import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { EditIcon, ShieldIcon } from '@eg/elements/components/Icons';
import Price from '@eg/elements/Price';
import { Person } from 'kfo-common';
import * as React from 'react';
import { mapPaymentMethodToText } from '../../helpers/paymentHelper';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface CheckoutTariffDetailsProps {
    insuredPersons: Person[];
    paymentMethod: string;
    insuranceTotalFee: number;
    handleAction: HandleActionCallback;
}

export const CheckoutTariffDetails = (props: CheckoutTariffDetailsProps) => {
    const paymentMethodText = mapPaymentMethodToText(props.paymentMethod);
    const SHIELD_ICON_WIDTH = 48;

    return (<div style={{textAlign: 'center'}}>
            <h3 style={{margin: '0 0 3em 0'}} data-component-id="checkout-tariff-data-header">Ihre Angaben</h3>
            <Card padding="normal" id="tarif-data" style={{textAlign: 'left'}}>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-12 esc_col-m-1 removeBorderBottom" style={{textAlign: 'center'}}>
                            <ShieldIcon width={SHIELD_ICON_WIDTH} height={SHIELD_ICON_WIDTH}/>
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-6 headline-variant">
                            <div style={{fontSize: '1.25em', fontWeight: 'bolder'}} data-component-id="checkout-tariff-data-variant">
                                ERGO Kieferorthopädie Sofortschutz für Kinder
                            </div>
                            <div data-component-id="checkout-tariff-data-variant-additions">
                                für {props.insuredPersons.length} Kind{props.insuredPersons.length > 1 && 'er'}
                            </div>
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-5 headline-price">
                            <Price data-component-id="netto-price-with-payment"
                                   value={props.insuranceTotalFee}
                                   suffix={paymentMethodText}
                                   style={{fontSize: '1.25em', fontFamily: '"FS Me",Arial,Helvetica,sans-serif'}}/>
                        </div>
                    </div>
                </div>
            </Card>
            <Button width="auto" variant="text-link" size="large" style={{fontWeight: 'bold'}} iconLeft={EditIcon}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Link_TarifdatenAendern);
                        props.handleAction(NavigationAction.DIRECT_JUMP_FEE_PAGE);
                    }}
                    data-component-id="checkout-tariff-data-edit-fee-button">
                ändern
            </Button>
        </div>
    );
};

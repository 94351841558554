export enum TrackingElement {
    Button_Zurueck = 'Button_Zurueck',
    Button_Weiter = 'Button_Weiter',
    Link_IDDBeratung = 'Link_IDDBeratung',
    Link_LeistungenAnzeigen = 'Link_LeistungenAnzeigen',
    Icon_InfoTarifdaten = 'Icon_InfoTarifdaten',
    Button_ErneutVersuchenFehlerMeldung = 'Button_ErneutVersuchenFehlerMeldung',
    OTRNavi_Tarifdaten = 'OTRNavi_Tarifdaten',
    OTRNavi_Beitrag = 'OTRNavi_Beitrag',
    OTRNavi_Persoenliches = 'OTRNavi_Persoenliches',
    Link_DatenVersicherungsnehmer = 'Link_DatenVersicherungsnehmer',
    Link_AnschriftUebernahme = 'Link_AnschriftUebernahme',

    Link_AngebotAnfordern = 'Link_AngebotAnfordern',

    Link_TarifdatenAendern = 'Link_TarifdatenAendern',
    Link_VersicherungsnehmerAendern = 'Link_VersicherungsnehmerAendern',
    Link_VersichertePersonAendern = 'Link_VersichertePersonAendern',
    Link_BankverbindungAendern = 'Link_BankverbindungAendern',

    Button_Add_Child = 'Button_WeiteresKindBersichern',
    Button_Remove_Child = 'Icon_GeburtsDatumLoeschen',
    Button_Save_Child = 'Button_Save_Child',
    Link_BearbeitenVP = 'Link_BearbeitenVP',

    Checkbox_Beratungsverzicht = 'Checkbox_Beratungsverzicht',
    Button_IDDDownload = 'Button_IDDDownload',
    Button_OnlineAbschliessen = 'Button_OnlineAbschliessen',
    Button_ZahntarifUeberAchtzehn = 'Link_ErwachsenesKindAbsichern',
    Button_TarifdatenAendern = 'Button_TarifdatenAendern',
    Button_ZahlweiseAendern = 'Button_ZahlweiseAendern',

    // Requestoffer
    Button_AngebotPerPost = 'Button_AngebotPerPost',
    Button_AngebotPerEmail = 'Button_AngebotPerEmail',
    Button_AngebotPDFHerunterladen = 'Button_AngebotPDFHerunterladen',
    Button_WeiterMitAbschluss= 'Button_WeiterMitAbschluss',
    Link_AngebotPDFerneutHerunterladen= 'Link_AngebotPDFerneutHerunterladen'
}

export enum TrackingErrorType {
    Sonstige = 'Sonstige'
}

const AKTIONSNUMMER_COOKIENAME: string = 'aktionNr=';
const AKTIONSNUMMER_INDEX = 0;
const VERMITTLERNUMMER_INDEX = 1;
const FREMD_ID_COOKIENAME: string = 'fid=';
const FREMD_ID_INDEX = 0;

export const decodeAktionNrCookie = (index: number = 0): string => {
  const decodedCookieString = decodeURIComponent(document.cookie);
  const cookieList: string[] = decodedCookieString.split(';');
  const aktionsNummerCookie: string | undefined = cookieList.find(element =>
    element.includes(AKTIONSNUMMER_COOKIENAME)
  );
  if (aktionsNummerCookie) {
    const aktionsNummernListe: string | undefined =
      aktionsNummerCookie.split('=')[1];
    if (aktionsNummernListe) {
      return aktionsNummernListe.split(',')[index];
    }
  }
  return '';
};

export const decodeFremdIdCookie = (index: number = 0): string => {
  const decodedCookieString = decodeURIComponent(document.cookie);
  const cookieList: string[] = decodedCookieString.split(';');
  const fremdIdCookie: string | undefined = cookieList.find(element =>
    element.trim().startsWith(FREMD_ID_COOKIENAME)
  );
  if (fremdIdCookie) {
    const fidValue = fremdIdCookie.split('=')[1];
    if (fidValue) {
      const extractedValue = fidValue.split(',')[index];
      return extractedValue;
    }
  }
  return '';
};

export const extractAktionsNummerFromCookie = (): string => {
  return decodeAktionNrCookie(AKTIONSNUMMER_INDEX);
};

export const extractVermittlerNummerFromCookie = (): string => {
  return decodeAktionNrCookie(VERMITTLERNUMMER_INDEX);
};

export const extractFremdIdFromCookie = () => {
  return decodeFremdIdCookie(FREMD_ID_INDEX);
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ErrorLogKey;

(function (ErrorLogKey) {
  ErrorLogKey["UNKNOWN"] = "UNKNOWN";
  ErrorLogKey["LOAD_POLYFILL_SCRIPT_ERROR"] = "LOAD_POLYFILL_SCRIPT_ERROR";
  ErrorLogKey["NO_PRICES"] = "NO_PRICES";
})(ErrorLogKey = exports.ErrorLogKey || (exports.ErrorLogKey = {}));

exports.ERROR_LOG_KEY = 'errorLogKey';
exports.MESSAGE_KEY = 'message';
exports.MESSAGE_MAX_STRING_SIZE = 2000;
var OfferType;

(function (OfferType) {
  OfferType["DIRECT_ONLINE"] = "online";
  OfferType["WRITTEN_POSTAL"] = "post";
  OfferType["WRITTEN_EMAIL"] = "email";
})(OfferType = exports.OfferType || (exports.OfferType = {}));
import FormRow from '@eg/elements/FormRow';
import Input from '@eg/elements/Input';
import InputRow from '@eg/elements/InputRow';
import { Field, FieldProps, Form, Formik } from 'formik';
import { ibanFormatter, mapDateToDateIsoString, mapToGermanDate, sanitizeIban } from 'kfo-common';
import * as React from 'react';
import Footer from '../components/Footer';
import { Headline } from '../components/Headline';
import { ScrollToError } from '../components/ScrollToError';
import { emptyFunction } from '../helpers/emptyFunction';
import { scrollToTop } from '../helpers/scrolling';
import { getErrorMessage } from '../helpers/validationHelpers';
import { NavigationAction } from '../routing/StateMachineTypes';
import { validateIban } from '../services/api';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createBankingDetailsSchema } from '../validation/BankingDetailsPage';

export interface BankingDetailsPageData extends StoreStateUpdater<BankingDetailsPageData> {
    iban: string;
    vorname: string;
    nachname: string;
}

type UpdateBankingDetailsCallback = (values: Partial<BankingDetailsPageData>, callback?: () => void) => void;

const BankingDetailsPage = (props: PagePropsWithValues<BankingDetailsPageData>) => {
    const [isValidating, setIsValidating] = React.useState(false);
    const [bankingDetailsValid, setBankingDetailsValid] = React.useState(false);

    React.useEffect(() => {
        scrollToTop();
        validateWithIbanService(emptyFunction, setIsValidating, setBankingDetailsValid, sanitizeIban(props.storeState.iban));
    }, [props.storeState.iban]);

    return (<Formik
        initialValues={{
            iban: ibanFormatter(props.storeState.iban)
        }}
        onSubmit={values => {
            const validatedValues = {
                ...props.storeState,
                ...createBankingDetailsSchema(bankingDetailsValid).cast(values)
            };
            props.storeState.update(validatedValues);
            props.handleAction(NavigationAction.NEXT);
        }}
        validationSchema={createBankingDetailsSchema(bankingDetailsValid)}
    >
        {form => (
            <Form style={{textAlign: 'center'}}>
                <Headline>
                    Bitte geben Sie Ihre Bankverbindung an.
                </Headline>
                {renderSepaPaymentInfoTextPartOne()}
                {renderAccountData(props.storeState.update, setIsValidating, setBankingDetailsValid, props.storeState.vorname,
                    props.storeState.nachname)}
                {renderSepaPaymentInfoTextPartTwo()}
                {renderMandatText()}
                <ScrollToError formik={form}/>
                <Footer showLoadingSpinner={isValidating}
                        handleAction={props.handleAction}
                        onNextClick={() => undefined}
                        showNavigateToOffer={true}/>
            </Form>
        )}
    </Formik>);

};

const renderSepaPaymentInfoTextPartOne = (): JSX.Element => {
    return (
        <div id="SepaPaymentInfoTextPartOne" style={{marginTop: '3em', marginBottom: '3em'}}>
            <h3>SEPA-Lastschriftmandat</h3>
            <p>
                Ich ermächtige die <b>ERGO Krankenversicherung AG</b> (Gläubiger-ID DE73DUE00000021741),
                Zahlungen von meinem Konto mittels Lastschrift einzuziehen.
            </p>
            <p>
                Zugleich weise ich mein Kreditinstitut an, diese auf mein Konto
                gezogenen Lastschriften einzulösen. Der SEPA-Basislastschrift-Einzug
                wird mir spätestens 5 Kalendertage im Voraus unter Angabe der weiteren
                Fälligkeitstermine angekündigt.
            </p>
        </div>
    );
};

const renderSepaPaymentInfoTextPartTwo = (): JSX.Element => {
    return (
        <div id="SepaPaymentInfoTextPartTwo" style={{fontSize: '0.8em', marginTop: '1em', marginBottom: '3em'}}>
            <h4>Hinweis</h4>
            <p>
                Ich kann innerhalb von 8 Wochen, beginnend mit dem Belastungsdatum, die
                Erstattung des belasteten Betrags verlangen. Es gelten dabei die mit
                meinem Kreditinstitut vereinbarten Bedingungen.
            </p>
        </div>
    );
};

const renderAccountData = (ibanUpdateCallback: UpdateBankingDetailsCallback, setIsValidating: (value: boolean) => void,
                           setBankingDetailsValid: (value: boolean) => void, vorname: string, nachname: string) => {
    return (<div style={{marginLeft: '6em'}}>
            {renderIBANField(ibanUpdateCallback, setIsValidating, setBankingDetailsValid)}
            {renderDatumForm()}
            {renderKontoForm(vorname, nachname)}
        </div>
    );
};

const renderIBANField = (ibanUpdateCallback: UpdateBankingDetailsCallback, setIsValidating: (value: boolean) => void,
                         setBankingDetailsValid: (value: boolean) => void): JSX.Element => {
    return <Field
        name="iban"
        data-component-id={'ibanField'}
        render={({field, form}: FieldProps<React.ChangeEvent<HTMLInputElement>>) => {
            const {setFieldValue, setFieldTouched} = form;
            const touchField = () => setFieldTouched(field.name);
            const maxInputLength = 43;

            return (
                <InputRow
                    id={field.name}
                    name={field.name}
                    error={getErrorMessage(form, field)}
                    value={field.value}
                    onBlur={touchField}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const sanitizedIban = sanitizeIban(event.target.value);
                        setFieldValue(field.name, event.target.value);
                        validateWithIbanService(touchField, setIsValidating, setBankingDetailsValid, sanitizedIban);
                        ibanUpdateCallback({iban: sanitizedIban});
                    }}
                    label={'IBAN'}
                    placeholder={'DE'}
                    data-component-id={field.name}
                    tooltip={'Ihre IBAN finden Sie auf Ihrer Bankkarte oder auf Ihrem Kontoauszug.'}
                    maxLength={maxInputLength}
                />);
        }}/>;
};

const validateWithIbanService = (touchField: () => void, setIsValidating: (value: boolean) => void, setBankingDetailsValid: (value: boolean) => void,
                                 iban?: string) => {
    setIsValidating(true);
    if (iban && isIbanCorrectLength(iban)) {
        validateIban(iban)
            .then(result => {
                setIsValidating(false);
                setBankingDetailsValid(result.isValidIban);
                touchField();
            })
            .catch(() => {
                setBankingDetailsValid(false);
                setIsValidating(false);
                touchField();
            });
    } else {
        setBankingDetailsValid(false);
        setIsValidating(false);
        touchField();
    }
};

const isIbanCorrectLength = (iban: string) => {
    const minimalIbanLength = 22;
    return iban.length >= minimalIbanLength;
};

const renderDatumForm = (): JSX.Element => {
    const currentDate = mapToGermanDate(mapDateToDateIsoString(new Date()));
    return (
        <FormRow label="Datum">
            <Input value={currentDate}
                   readOnly={true}
                   disabled={true}
                   data-component-id="ibanDatum"/>
        </FormRow>);
};

const renderKontoForm = (vorname: string, nachname: string): JSX.Element => {
    return (
        <FormRow label="Kontoinhaber">
            <Input value={`${vorname} ${nachname}`}
                   readOnly={true}
                   disabled={true}
                   data-component-id="ibanKontoinhaber"/>
        </FormRow>);
};

const renderMandatText = (): JSX.Element => {
    return <p style={{fontSize: '0.85em', marginTop: '4em', marginBottom: '3em'}}>
        Sollte bereits ein Mandat für die oben genannte Kontoverbindung
        bestehen, bin ich damit einverstanden, dass das von mir bereits erteilte
        SEPA&#8209;Lastschriftmandat auch für den Einzug der Beiträge für diesen
        Versicherungsvertrag genutzt wird.
    </p>;
};

export default BankingDetailsPage;

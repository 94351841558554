import LayoutBox from '@eg/elements/LayoutBox';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { Message } from 'kfo-common';
import * as React from 'react';
import { HandleActionCallback, StateData, StateDefinition, UpdateFunction } from '../routing/StateMachine';
import {
    CHECKOUT_PAGES,
    FEE_PAGES,
    NavigationAction,
    PERSONAL_INFORMATION_PAGES,
    StateName
} from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import ErrorModal from './ErrorModal';
import { PageStepIndex, StepNavigation } from './StepNavigation';

export interface PageWrapperProps {
    currentState: StateDefinition<{}>;
    inputData: StateData<{}>;
    handleAction: HandleActionCallback;
    // tslint:disable-next-line:no-any
    updateApp: UpdateFunction<any>;
    updateCalling: boolean;
    openErrorModal: boolean;
    messages: Message[];
}

interface PageWrapperState {
    isPageError: boolean;
    showErrorDialog: boolean;
}

export class PageWrapper extends React.Component<PageWrapperProps, PageWrapperState> {
    constructor(props: PageWrapperProps) {
        super(props);
        this.state = {
            isPageError: false,
            showErrorDialog: false
        };
    }

    private readonly onError = (e: Error) => {
        this.setState({
            isPageError: true
        });
    };

    private readonly handleErrorModalRepeatClick = () => {
        trackElementClicked(TrackingElement.Button_ErneutVersuchenFehlerMeldung);
        if (this.state.isPageError) {
            this.setState({
                isPageError: false
            });
            window.location.reload();
            return;
        }
        this.props.handleAction(NavigationAction.REPEAT_CALL);
    };

    private readonly getTabIndex = (stateName: StateName) => {
        if (FEE_PAGES.find(p => p === stateName)) {
            return PageStepIndex.FEE;
        } else if (PERSONAL_INFORMATION_PAGES.find(p => p === stateName)) {
            return PageStepIndex.PERSONAL_INFORMATION;
        } else if (CHECKOUT_PAGES.find(p => p === stateName)) {
            return PageStepIndex.CHECKOUT;
        }
        return PageStepIndex.TARIFF_DATA;
    };

    private readonly goToPageByTabIndex = (index: number): void => {

        let navigationAction: NavigationAction;
        switch (index) {
            case PageStepIndex.TARIFF_DATA:
                trackElementClicked(TrackingElement.OTRNavi_Tarifdaten);
                navigationAction = NavigationAction.BREADCRUMB_TARIFF_DATA;
                break;
            case PageStepIndex.FEE:
                trackElementClicked(TrackingElement.OTRNavi_Beitrag);
                navigationAction = NavigationAction.BREADCRUMB_FEE;
                break;
            case PageStepIndex.PERSONAL_INFORMATION:
                trackElementClicked(TrackingElement.OTRNavi_Persoenliches);
                navigationAction = NavigationAction.BREADCRUMB_PERSONAL_INFORMATION;
                break;
            default:
                throw Error('Wrong index for Stepnavigation');
        }

        this.props.handleAction(navigationAction);
    };

    // TODO mf 03.04.2020: Dev Console sauber einbauen, sodass sie nur im Debug-Mode und nicht in Praxis angezeigt wird
    // solange bleibt sie erstmal ausgebaut
    // {this.props.messages && <DevDebugWindow messages={this.props.messages}/>}

    public render() {
        return <LayoutBox direction="column">
            {(this.props.openErrorModal || this.state.isPageError) &&
            <ErrorModal onClick={this.handleErrorModalRepeatClick}
                        open={true}/>}
            {!this.props.openErrorModal && !this.state.isPageError && <>
                {this.renderStepNavigation()}

                <div data-component-id="main-view">
                    {this.props.updateCalling && <LoadingSpinner show={true}/>}
                    {this.props.currentState.render &&
                    this.props.currentState.render(this.props.inputData, this.props.handleAction, this.props.updateApp, this.onError)}

                </div>
            </>
            }
        </LayoutBox>;
    }

    private renderStepNavigation() {
        const currentTabIndex: number = this.getTabIndex(this.props.currentState.name);

        return <StepNavigation
            activeTab={currentTabIndex}
            goToStepByIndex={this.goToPageByTabIndex}
        />;
    }
}

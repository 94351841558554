import { isValidIsoDateFormat } from 'kfo-common';
import { addMethod, mixed, object, Ref, ref, Schema, string, StringSchema } from 'yup';
import { CustomMixedSchema, DateErrorMessages, isDateYoungerThan } from './date';

export const streetErrorMessage = 'Bitte geben Sie eine gültige Strasse ein.';
export const housenumberErrorMessage = 'Bitte geben Sie eine gültige Hausnummer ein.';
export const postalCodeErrorMessage = 'Bitte geben Sie eine gültige Postleitzahl ein.';
export const cityErrorMessage = 'Bitte geben Sie einen gültigen Ort ein.';

export const anredeErrorMessage = 'Bitte wählen Sie eine Anrede aus.';
export const firstNameErrorMessage = 'Bitte geben Sie einen gültigen Vornamen ein.';
export const surnameErrorMessage = 'Bitte geben Sie einen gültigen Nachnamen ein.';
export const birthdateErrorMessage = 'Bitte geben Sie ein gültiges Geburtsdatum ein.';

export const emailErrorMessage = 'Bitte geben Sie eine gültige E-Mail Adresse ein.';
export const prefixErrorMessage = 'Bitte geben Sie eine gültige Vorwahl ein.';
export const prefixErrorMessageTwo = 'Bitte geben Sie die Vorwahl mit führender "0" ein';
export const phonenumberErrorMessage = 'Bitte geben Sie ein gültige Rufnummer ein.';
export const phonenumberErrorMessageTwo = 'Bitte geben Sie eine Vorwahl für Ihre Rufnummer ein.';
export const birthdateVNTooYoungErrorMessage = `Der Versicherungsnehmer muss mindestens 18 Jahre alt sein.`;
export const emailPattern = /^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-ZäöüÄOÜ0-9-]+\.)+[a-zA-Z]{2,6}$/;

export function createPersonalDataSchema(isDetailedPersonalData: boolean, isEmailRequired?: boolean): Schema<{}> {

    const minAgeVN = 18;
    const address = {
        strasse: string().trim().nullable()
            .required(streetErrorMessage)
            .test('streetPattern', streetErrorMessage, value => {
                const streetPattern = /^[0-9A-Za-z\u00c0-\u01ff'. -]+$/;
                return streetPattern.test(value);
            }),
        hausnummer: string().trim().nullable()
            .required(housenumberErrorMessage)
            .test('housenumberPattern', housenumberErrorMessage, value => {
                const housenumberPattern = /^\d+[a-zA-Z\d _.\-/]*$/;
                return housenumberPattern.test(value);
            }),
        plz: string().trim().nullable()
            .required(postalCodeErrorMessage)
            .test('postalCodePattern', postalCodeErrorMessage, value => {
                const zipPattern = /^\d+$/;
                return zipPattern.test(value);
            }),
        ort: string().trim().nullable()
            .required(cityErrorMessage)
            .test('cityPattern', cityErrorMessage, value => {
                const cityPattern = /^[0-9A-Za-zäüöÄÜÖ\u00c0-\u01ff ./)(-]+$/;
                return cityPattern.test(value);
            })
    };

    let personalDataSchema = object().shape({
        anrede: string().nullable().required(anredeErrorMessage),
        vorname: string().trim().nullable()
            .required(firstNameErrorMessage)
            .test('firstNamePattern', firstNameErrorMessage, value => {
                const firstNamePattern = /^[a-zA-Z\u00c0-\u01ff -.]+$/;
                return firstNamePattern.test(value);
            }),
        nachname: string().trim().nullable()
            .required(surnameErrorMessage)
            .test('surnamePattern', surnameErrorMessage, value => {
                const surnamePattern = /^[a-zA-Z\u00c0-\u01ff -.]+$/;
                return surnamePattern.test(value);
            }),
        birthdate: (mixed() as CustomMixedSchema)
            .required(birthdateErrorMessage)
            .isValidIsoDateFormat(),
        adresse: object(address)
    });

    if (isDetailedPersonalData) {
        personalDataSchema = personalDataSchema.concat(object().shape({
            vorwahl: (string() as CustomMixedSchemaPersonalData)
                .isFullPhoneNumber(ref('rufnummer'), prefixErrorMessage)
                .nullable()
                .notRequired()
                .test('prefixPattern', prefixErrorMessage, value => {
                    const prefixPattern = /^\d+$/;
                    return (prefixPattern.test(value) || (value === '' || value === undefined));
                })
                .test('prefixFirstZero', prefixErrorMessageTwo, (value: string | undefined) => {
                    return value ? (value.startsWith('0') || value === '') : true;
                }),
            rufnummer: (string() as CustomMixedSchemaPersonalData)
                .isFullPhoneNumber(ref('vorwahl'), phonenumberErrorMessageTwo)
                .nullable()
                .notRequired()
                .test('phonenumberPattern', phonenumberErrorMessage, value => {
                    const phonenumberPattern = /^\d+$/;
                    return (phonenumberPattern.test(value) || (value === '' || value === undefined));
                }),
            birthdate: (mixed() as CustomMixedSchema)
                .test({
                    message: ({value}) => {
                        if (isDateYoungerThan(new Date(), value, minAgeVN)) {
                            return birthdateVNTooYoungErrorMessage;
                        } else {
                            return '';
                        }
                    },
                    test: (value: string) => {
                        if (isDateYoungerThan(new Date(), value, minAgeVN)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                })
        }));
    }

    if (isEmailRequired) {
        personalDataSchema = personalDataSchema.concat(object().shape({
            email: string().trim().required(emailErrorMessage)
                .test('emailPattern', emailErrorMessage, value => emailPattern.test(value))
        }));
    } else {
        personalDataSchema = personalDataSchema.concat(object().shape({
            email: string().trim().nullable().notRequired()
                .test('emailPattern', emailErrorMessage, value => {
                    return (emailPattern.test(value) || (value === '' || value === undefined));
                })
        }));
    }

    return personalDataSchema;
}

interface CustomMixedSchemaPersonalData extends StringSchema {
    isFullPhoneNumber(prefix: Ref, message: string): this;
    isValidIsoDateFormat(message?: string): this;
}

addMethod(string, 'isFullPhoneNumber', function(phonennumber: Ref, message: string) {
    // tslint:disable-next-line:no-invalid-this
    return this.test({
        name: 'isValidPrefix',
        exclusive: true,
        message,
        // tslint:disable-next-line:object-literal-shorthand
        test: function(prefix: string) {
            // tslint:disable-next-line:no-invalid-this no-any
            return (this as any).resolve(phonennumber) ? !!prefix : true;
        }
    });
});

addMethod(mixed, 'isValidIsoDateFormat', function(message?: string) {
    // tslint:disable-next-line:no-invalid-this
    return this.test({
        name: 'isValidIsoDateFormat',
        exclusive: true,
        message: message || DateErrorMessages.FORMAT,
        test: isValidIsoDateFormat
    });
});

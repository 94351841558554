import {
  hashData,
  PHONE_NUMBER_PREFIXES,
  preparePhoneNumberForHashing
} from '@b2c-commons/utils';
import {
  DatalayerProductAttributes,
  DatalayerUserProfileAttributes,
  DatalayerWithoutEvent
} from '@eg/tracking/datalayer.models';
import { IsoDateString, mapToGermanDate } from 'kfo-common';
import { mapAddressesToPrintableText } from '../helpers/addressesHelper';
import { getConfigVariable } from '../helpers/configurableVariables';
import { TrackingData } from './TrackingData';

const createBasisTrackingObject = () => {
  return {
    product: [
      {
        attributes: {
          tariffOptions1: 'Rechner=kfo2020'
        } as DatalayerProductAttributes,
        productInfo: { productName: getProductName() as string },
        category: { primaryCategory: getProductCategory() as string }
      }
    ],
    user: [
      {
        profile: {
          attributes: {} as DatalayerUserProfileAttributes,
          address: {},
          profileInfo: {}
        }
      }
    ]
  };
};

const getProductName = () => {
  return getConfigVariable('trackingKuerzel') || 'kfo';
};

const getProductCategory = () => {
  return getConfigVariable('trackingProduktgruppe') || 'ZahnZusatz';
};

export const mapTrackingData = (
  trackingData: TrackingData
): DatalayerWithoutEvent => {
  const trackingObject = createBasisTrackingObject();
  const productAttributes = trackingObject.product[0].attributes;
  const profile = trackingObject.user[0].profile;

  if (!trackingData) {
    return trackingObject;
  }

  if (trackingData.insuredPersons) {
    productAttributes.tariffOptions1 += `|AnzVN=${trackingData.insuredPersons.length}`;
    profile.attributes.birthdayInsuredPerson = getYear(
      trackingData.insuredPersons[0].birthdate
    );
    for (let i = 1; i < trackingData.insuredPersons.length; i++) {
      productAttributes.tariffOptions1 += `|birInsPers${i + 1}=${getYear(
        trackingData.insuredPersons[i].birthdate
      )}`;
    }
  }

  if (trackingData.paymentMethod) {
    productAttributes.tariffOptions1 += `|Zahlung=${mapPaymentMethod(
      trackingData.paymentMethod
    )}`;
  }

  if (trackingData.insuranceBegin) {
    productAttributes.insuranceStart = mapToGermanDate(
      trackingData.insuranceBegin
    );
  }

  if (trackingData.policyHolder) {
    const adresse = trackingData.policyHolder.adresse;
    const vn = trackingData.policyHolder;
    const fullPhoneNumber =
      vn.vorwahl &&
      vn.rufnummer &&
      preparePhoneNumberForHashing(`${vn.vorwahl}${vn.rufnummer}`);

    profile.attributes.birthday = getYear(trackingData.policyHolder.birthdate);
    profile.address = {
      city: hashData(`${adresse && adresse.ort}`),
      line1: hashData(
        `${adresse && adresse.strasse} ${adresse && adresse.hausnummer}`
      ),
      postalCode: adresse ? adresse.plz : undefined
    };
    profile.attributes.gender = mapAddressesToPrintableText(
      trackingData.policyHolder.anrede
    );
    profile.profileInfo = {
      firstName: hashData(`${vn.vorname}`),
      lastName: hashData(`${vn.nachname}`),
      ...(Boolean(vn.email) && { email: hashData(`${vn.email}`) }),
      ...(fullPhoneNumber && {
        telephone: hashData(
          `${PHONE_NUMBER_PREFIXES.german}${fullPhoneNumber}`
        ),
        telephoneE164: hashData(
          `${PHONE_NUMBER_PREFIXES.e164}${fullPhoneNumber}`
        )
      })
    };
  }

  return trackingObject;
};

const getYear = (date?: IsoDateString) => {
  if (date) {
    return new Date(date).getFullYear().toString();
  }
  return undefined;
};

const mapPaymentMethod = (paymentMethod: string) => {
  switch (paymentMethod) {
    case 'MONATLICH':
      return '1/12';
    case 'VIERTELJAEHRLICH':
      return '1/4';
    case 'HALBJAEHRLICH':
      return '1/2';
    case 'JAEHRLICH':
      return '1/1';
    default:
      return undefined;
  }
};

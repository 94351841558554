import * as React from 'react';
import InsuredPersonsPage, { InsuredPersonsPageData } from '../../../pages/InsuredPersonsPage';
import { getOfferPageData } from '../../../services/api';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const requestOfferInsuredPersonsPage: StateDefinition<InsuredPersonsPageData> = {
    name: StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE,
    transitions: [{
        test: (action, data) => action === NavigationAction.NEXT,
        newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            resolveNewState: createOfferNavigation(StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE, [StateName.POLICY_HOLDER_PAGE]),
            newState: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuredPersons');
        const policyHolderAddress = response.policyHolder ? response.policyHolder.adresse : undefined;
        return {
            userInput: {
                policyHolderAddress,
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            businessId: response.businessId,
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuredPersonsPage
            storeState={{
                businessId: inputData.businessId,
                insuredPersons: inputData.userInput.insuredPersons,
                policyHolderAddress: inputData.userInput.policyHolderAddress,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

import * as React from 'react';
import BirthdatePage, { BirthdatePageData } from '../../pages/BirthdatePage';
import { getOfferPageData, updateBirthdatePageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const birthdatePage: StateDefinition<BirthdatePageData> = {
    name: StateName.BIRTHDATE_PAGE,
    transitions: [{
        test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_BEGIN_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'birthdates');

        return {
            userInput: {
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updateBirthdatePageData(inputData.businessId, {
            insuredPersons: inputData.userInput.insuredPersons.map(person => {
                return {
                    personId: person.personId,
                    birthdate: person.birthdate
                };
            })
        });
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <BirthdatePage
            storeState={{
                insuredPersons: inputData.userInput.insuredPersons,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

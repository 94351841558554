"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ChannelType;

(function (ChannelType) {
  ChannelType["ERGO_PAGE"] = "ERGO_PAGE";
  ChannelType["AGENT_BROKER"] = "AGENT_BROKER";
  ChannelType["MAKLER"] = "MAKLER";
  ChannelType["COOP_SALES_PARTNER"] = "COOP_SALES_PARTNER";
})(ChannelType = exports.ChannelType || (exports.ChannelType = {}));
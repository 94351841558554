import * as ibanValidation from 'iban';
import { sanitizeIban } from 'kfo-common';
import { object, Schema, string } from 'yup';

const MAX_IBAN_CHARACTER = 22;

export function createBankingDetailsSchema(externalValidationResult: boolean): Schema<{}> {
    return object().shape({
            iban: string()
                .required('Bitte geben Sie eine gültige deutsche IBAN ein.')
                .test('maxIbanLength', 'Die IBAN darf maximal 22 Zeichen lang sein.', (iban: string) => {
                    return (sanitizeIban(iban).length <= MAX_IBAN_CHARACTER);
                })
                .test('validGermanIban', 'Bitte geben Sie eine gültige deutsche IBAN ein.', (iban: string) => {
                    return sanitizeIban(iban).toUpperCase().startsWith('DE');
                }).test('validIban', 'Bitte geben Sie eine gültige deutsche IBAN ein.', (iban: string) => {
                    return ibanValidation.isValid(sanitizeIban(iban));
                })
                .test('externalValidation', 'Bitte geben Sie eine gültige deutsche IBAN ein.', () => externalValidationResult)
        }
    );
}

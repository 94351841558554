export enum StateName {
    BIRTHDATE_PAGE = 'BirthdatePage',
    INSURANCE_BEGIN_PAGE = 'InsuranceBeginPage',
    FEE_PAGE = 'FeePage',
    POLICY_HOLDER_PAGE = 'PolicyHolderPage',
    INSURED_PERSONS_PAGE = 'InsuredPersonsPage',
    BANKING_DETAILS_PAGE = 'BankingDetailsPage',
    INSURANCE_SUMMARY_PAGE = 'InsuranceSummaryPage',
    INSURANCE_DOCUMENTS_PAGE = 'InsuranceDocumentsPage',
    FEED_BACK_PAGE = 'FeedBackPage',
    REQUEST_OFFER_POLICY_HOLDER_PAGE= 'RequestOfferPolicyHolderPage',
    REQUEST_OFFER_INSURED_PERSONS_PAGE = 'RequestOfferInsuredPersonsPage',
    REQUEST_OFFER_CHECKOUT_PAGE= 'RequestOfferCheckoutPage',
    REQUEST_OFFER_FEED_BACK_PAGE= 'RequestOfferFeedBackPage',
    DUMMY3 = 'Dummy3'
}

export const TARIFF_DATA_PAGES = [StateName.BIRTHDATE_PAGE, StateName.INSURANCE_BEGIN_PAGE];
export const FEE_PAGES = [StateName.FEE_PAGE];
export const PERSONAL_INFORMATION_PAGES = [StateName.POLICY_HOLDER_PAGE, StateName.INSURED_PERSONS_PAGE, StateName.BANKING_DETAILS_PAGE,
    StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE, StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE];
export const CHECKOUT_PAGES = [StateName.INSURANCE_SUMMARY_PAGE, StateName.INSURANCE_DOCUMENTS_PAGE, StateName.FEED_BACK_PAGE,
    StateName.REQUEST_OFFER_CHECKOUT_PAGE, StateName.REQUEST_OFFER_FEED_BACK_PAGE];
export const REQUEST_OFFER_PAGES = [StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE, StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE,
    StateName.REQUEST_OFFER_CHECKOUT_PAGE];

export enum NavigationAction {

    BROWSER_BACK = 'browser_back',
    BROWSER_NEXT = 'browser_next',
    START = 'start',
    NEXT = 'next',
    BACK = 'back',
    REPEAT_CALL = 'repeat_call',

    BREADCRUMB_TARIFF_DATA = 'breadcrumb_tariff_data',
    BREADCRUMB_FEE = 'breadcrumb_fee',
    BREADCRUMB_PERSONAL_INFORMATION = 'breadcrumb_personal_information',

    DIRECT_JUMP_TARRIF_DATA = 'directjump_tariff_data',
    DIRECT_JUMP_FEE_PAGE = 'directjump_fee_page',
    DIRECT_JUMP_POLICY_HOLDER_PAGE = 'directjump_policy_holder',
    DIRECT_JUMP_INSURED_PERSONS_PAGE = 'directjump_insured_person',
    DIRECT_JUMP_BANKING_DETAILS_PAGE = 'directjump_banking_details',
    DIRECT_JUMP_REQUEST_OFFER = 'directjump_request_offer',

    OFFER_DIRECT_ONLINE = 'offer_direct_online',
    OFFER_WRITTEN_POSTAL = 'offer_written_postal',
    OFFER_WRITTEN_EMAIL = 'offer_written_email'
}

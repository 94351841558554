import { ChannelType } from 'kfo-common';
import { AgentWindow, TrackingEvents } from '../types/Agency';
import {
  extractAktionsNummerFromCookie,
  extractFremdIdFromCookie,
  extractVermittlerNummerFromCookie
} from './cookieExtractor';

export const DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES = '8565';
export const DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES = '8504';
export const DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES = '01280200';

export const ACTION_NUMBER_COOKIE_KEY = 'aktionNr';
export const OE_NUMBER_QUERY_KEY = 'adh_oenr';
export const MARKETING_ACTION_NUMBER_KEY = 'actionNumber';
export const MARKETING_AGENT_NUMBER_KEY = 'agentNumber';
export const MARKETING_SALES_COOKIES_KEY = 'salesCookies';
export const APP_EVENT_DATA_KEY = 'appEventData';

export const FREMD_ID_COOKIE_KEY = 'fid';

export const getOenrFromQueryParam = (): string | null => {
  const searchString = window.location.search;
  if (searchString) {
    const params = new URLSearchParams(searchString);
    const oeNumber = params.get(OE_NUMBER_QUERY_KEY);
    return oeNumber ? oeNumber : null;
  }
  return null;
};

export const getOeNumberFromAdhopPages = (): string | null => {
  const { agencyData } = window as AgentWindow;
  return agencyData && agencyData.oenrNumber ? agencyData.oenrNumber : null;
};

export const getMarketingEventProperty = (property: string): string | null => {
  const appEventData = window[APP_EVENT_DATA_KEY] as Array<
    { event: string } & { [key: string]: any }
  >;
  if (appEventData) {
    const marketingEvent = appEventData.find(e => e.event === TrackingEvents.MARKETING
    );
    if (
      marketingEvent &&
      marketingEvent.miscellaneous &&
      marketingEvent.miscellaneous.marketing
    ) {
      return marketingEvent.miscellaneous.marketing[property] || null;
    } else {
      return null;
    }
  }
  return null;
};

export const getActionNumberFromMarketingEvent = (): string | null => {
  return getMarketingEventProperty(MARKETING_ACTION_NUMBER_KEY);
};

export const getAgentNumberFromMarketingEvent = (): string | null => {
  return getMarketingEventProperty(MARKETING_AGENT_NUMBER_KEY);
};

export const getActionNumberFromGlobalVariable = (): string | null => {
  const aktionNumberValueFromGlobalVariable =
    (window as any).defaultAktionNr || '';
  const [extractedActualActionNumber] =
    aktionNumberValueFromGlobalVariable.split(',');
  if (extractedActualActionNumber) {
    return extractedActualActionNumber;
  }
  return null;
};

export const getActionNumber = (): string => {
  const marketingActionNumber = getActionNumberFromMarketingEvent();
  if (marketingActionNumber) {
    return marketingActionNumber;
  }
  const cookiesActionNumber = extractAktionsNummerFromCookie();
  if (cookiesActionNumber) {
    return cookiesActionNumber;
  }

  const actionNumberFromGlobalVariable = getActionNumberFromGlobalVariable();
  if (actionNumberFromGlobalVariable) {
    return actionNumberFromGlobalVariable;
  }

  if (isAdhopPage() || isSitecorePage()) {
    return DEFAULT_ACTION_NUMBER_FOR_AGENT_PAGES;
  }
  return DEFAULT_ACTION_NUMBER_FOR_ERGO_DE_PAGES;
};

export const getChannelType = (): ChannelType => {
  if (isPartnerCooperationPage()) {
    return ChannelType.COOP_SALES_PARTNER;
  } else if (isAdhopPage() || isSitecorePage()) {
    return ChannelType.AGENT_BROKER;
  } else {
    return ChannelType.ERGO_PAGE;
  }
};

export const getOeNumber = (): string => {
  if (getChannelType() === ChannelType.ERGO_PAGE) {
    return DEFAULT_OE_NUMBER_FOR_ERGO_DE_PAGES;
  } else {
    return getOenrFromQueryParam() || getOeNumberFromAdhopPages() || '';
  }
};
export const getFremdId = ():  string => {
  const fremdId = extractFremdIdFromCookie();
  if (fremdId) {
    return fremdId;
  }
  return '';
};
export const isPartnerCooperationPage = (): boolean => {
  return !!getMarketingEventProperty(MARKETING_SALES_COOKIES_KEY);
};
export const isAdhopPage = (): boolean => !!getOeNumberFromAdhopPages();
export const isSitecorePage = (): boolean => !!getOenrFromQueryParam();

export const getAgencyParams = () => {
  const oeNummer = getOeNumber();
  const vermittlernummer =
    getAgentNumberFromMarketingEvent() || extractVermittlerNummerFromCookie();
  const aktionsnummer = getActionNumber();
  const channelType = getChannelType();
  const fremdId = getFremdId();

  return {
    oeNummer,
    vermittlernummer,
    aktionsnummer,
    channelType,
    fremdId
  };
};

import { GlobalTracking, LinkType } from '@eg/tracking';
import { NODE_ENV } from '../config';
import { StateName } from '../routing/StateMachineTypes';
import { TrackingElement, TrackingErrorType } from './trackingConstants';
import { TrackingData } from './TrackingData';
import { mapTrackingData } from './trackingDataMapper';
import { getTrackingPageName, mapPageData } from './trackingPageMapper';
import { mapTransactionData } from './trackingTransactionMapper';

const tracker = new GlobalTracking();
let trackingData: TrackingData;
let currentPage: StateName | undefined;

export const trackPageTransition = (targetPage?: StateName) => {
    try {
        currentPage = targetPage;
        tracker.datalayerTrackPageView(createTrackingObject());
        debug();
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

export const trackElementClicked = (clickedElement: TrackingElement) => {
    try {
        tracker.datalayerTrackGeneralClick(createClickTrackingObject(clickedElement), true);
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

export const trackElementClickImmediate = (clickedElement: TrackingElement) => {
    try {
        const trackingObjekt = {
            ...createTrackingObject(),
            ...createClickTrackingObject(clickedElement)
        };
        tracker.datalayerTrackGeneralClick(trackingObjekt);
        debug();
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

export const trackError = (errorType: TrackingErrorType) => {
    try {
        const trackingObjekt = {
            ...createTrackingObject(),
            miscellaneous: {
                errors: {
                    errorType,
                    errorFlag: 'true',
                    errorUrl: window.location.href
                }
            }
        };
        tracker.datalayerTrackGeneralClick(trackingObjekt);
        debug();
    } catch (e) {
        // Eigentlich interessiert es uns nicht wenn tracking nicht funktioniert
    }
};

const createTrackingObject = () => {
    return {
        ...mapPageData(currentPage),
        ...mapTrackingData(trackingData),
        ...mapTransactionData(trackingData, currentPage)
    };
};

const createClickTrackingObject = (clickedElement: TrackingElement) => {
    return {
        eventdetails: {
            clickedElement,
            clickedElementPage: getTrackingPageName(currentPage),
            clickType: LinkType.CustomLink
        }
    };
};

export const addTrackingData = (data: TrackingData) => {
    trackingData = {...trackingData, ...data};
};

const debug = () => {
    if (NODE_ENV === 'development') {
        // tslint:disable-next-line
        console.log(JSON.stringify(window.appEventData.slice(-1).pop(), null, 2));
    }
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // tslint:disable:no-magic-numbers binary-expression-operand-order

exports.TIME_OF_ONE_MINUTE = 60 * 1000;
exports.TIME_OF_ONE_DAY = 24 * 60 * exports.TIME_OF_ONE_MINUTE; // tslint:enable:no-magic-numbers binary-expression-operand-order
// Only date part in ISO string e.g. yyyy-mm-dd

exports.ISO_DATE_LENGTH = 10;

exports.mapDateToDateObject = function (date) {
  return {
    day: exports.getTwoDigitNumber(date.getDate()),
    month: exports.getTwoDigitNumber(date.getMonth() + 1),
    year: exports.getTwoDigitNumber(date.getFullYear())
  };
};

exports.mapDateToDateIsoString = function (date) {
  return date.toISOString().slice(0, exports.ISO_DATE_LENGTH);
};

exports.mapToGermanDate = function (dateText) {
  if (dateText) {
    var date = new Date(dateText);
    return exports.getTwoDigitNumber(date.getDate()) + "." + exports.getTwoDigitNumber(date.getMonth() + 1) + "." + date.getFullYear();
  }

  return undefined;
};

exports.parseDate = function (isoDate) {
  if (isoDate) {
    return new Date(isoDate);
  }

  return undefined;
};

exports.getTwoDigitNumber = function (input) {
  // tslint:disable-next-line:no-magic-numbers
  var isOneDigitNumber = Number(input) < 10;
  return "" + (isOneDigitNumber ? '0' : '') + Number(input);
};

function mapDateInputToIsoDateString(originalValue) {
  var newDateObject = originalValue;

  if (!newDateObject || !newDateObject.day || !newDateObject.month || !newDateObject.year) {
    return '';
  }

  var isoDate = newDateObject.year + "-" + exports.getTwoDigitNumber(newDateObject.month) + "-" + exports.getTwoDigitNumber(newDateObject.day);

  if (isValidIsoDateFormat(isoDate)) {
    return isoDate;
  } else {
    return '';
  }
}

exports.mapDateInputToIsoDateString = mapDateInputToIsoDateString;

function isValidIsoDateFormat(value) {
  if (value) {
    var pattern = /(^(19|20)\d\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$)/;
    return pattern.test(value);
  } else {
    return false;
  }
}

exports.isValidIsoDateFormat = isValidIsoDateFormat;

exports.mapIsoDateStringToDateInput = function (isoDate) {
  if (isoDate && isValidIsoDateFormat(isoDate)) {
    var date = new Date(isoDate);
    return {
      year: exports.getTwoDigitNumber(date.getFullYear()),
      month: exports.getTwoDigitNumber(date.getMonth() + 1),
      day: exports.getTwoDigitNumber(date.getDate())
    };
  }

  return {
    year: '',
    month: '',
    day: ''
  };
};
import * as React from 'react';
import BankingDetailsPage, { BankingDetailsPageData } from '../../pages/BankingDetailsPage';
import { getOfferPageData, updateBankingDetailsPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const bankingDetailsPage: StateDefinition<BankingDetailsPageData> = {
    name: StateName.BANKING_DETAILS_PAGE,
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_SUMMARY_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURED_PERSONS_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'bankingDetails');

        return {
            userInput: {
                vorname: response.policyHolder ? response.policyHolder.vorname : '',
                nachname: response.policyHolder ? response.policyHolder.nachname : '',
                iban: response.iban,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        await updateBankingDetailsPageData(inputData.businessId, inputData.userInput);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <BankingDetailsPage
            storeState={{
                iban: inputData.userInput.iban,
                vorname: inputData.userInput.vorname,
                nachname: inputData.userInput.nachname,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

import * as React from 'react';
import FeePage, { FeePageData } from '../../pages/FeePage';
import { getOfferPageData, updateFeePage } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const feePage: StateDefinition<FeePageData> = {
    name: StateName.FEE_PAGE,
    transitions: [{
        test: (action, data) => action === NavigationAction.NEXT,
        newState: StateName.POLICY_HOLDER_PAGE
    },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURANCE_BEGIN_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_TARRIF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        // Direct jumps
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceFee');

        return {
            businessId: response.businessId,
            userInput: {
                insuranceBegin: response.insuranceBegin,
                insuranceFeeTotal: response.insuranceFeeTotal,
                paymentMethod: response.paymentMethod,
                insuredPersons: response.insuredPersons,
                personalizedFee: response.personalizedFee,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updateFeePage(inputData.businessId, {
            paymentMethod: inputData.userInput.paymentMethod
        });
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <FeePage
            storeState={{
                businessId: inputData.businessId,
                insuranceFeeTotal: inputData.userInput.insuranceFeeTotal,
                insuranceBegin: inputData.userInput.insuranceBegin,
                insuredPersons: inputData.userInput.insuredPersons,
                paymentMethod: inputData.userInput.paymentMethod,
                personalizedFee: inputData.userInput.personalizedFee,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

export enum TrackingEvents {
  PAGE_LOAD = 'PageLoaded',
  GENERAL_CLICK = 'GeneralClick',
  MARKETING = 'MarketingLogicReady'
}

export interface TrackingContent {
  event?: TrackingEvents;
  miscellaneous?: TrackingErrors;
}

export interface TrackingErrorDetails {
  errorMessages: string;
  errorType: string;
  errorFlag?: boolean;
}

export interface TrackingErrors extends MarketingTrackingEventDetails {
  errors?: TrackingErrorDetails;
}

interface MarketingTrackingElements {
  actionNumber: string;
  agentNumber: string;
  salesCookie: string;
}

interface AgencyData {
  oenrNumber: string;
}

export interface MarketingTrackingEventDetails {
  marketing?: MarketingTrackingElements;
}

export type AgentWindow = Window &
  typeof globalThis & {
    appEventData?: TrackingContent[];
    agencyData?: AgencyData;
  };

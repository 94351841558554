import { Addresses } from 'kfo-common';

export function mapAddressesToPrintableText(addresses?: string): string | undefined {
    switch (addresses) {
        case Addresses.MR:
            return 'Herr';
        case Addresses.MS:
            return 'Frau';
        default:
            return undefined;
    }
}

export function getAddressesFromStringValue(stringValue: string): string | undefined {
    switch (stringValue) {
        case Addresses.MR:
            return Addresses.MR;
        case Addresses.MS:
            return Addresses.MS;
        default:
            return undefined;
    }
}

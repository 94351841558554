import { IsoDateString, Person } from 'kfo-common';
import * as React from 'react';
import { CheckoutBankdata } from '../components/checkout/CheckoutBankdata';
import { CheckoutInsuredPersons } from '../components/checkout/CheckoutInsuredPersons';
import { CheckoutPolicyHolder } from '../components/checkout/CheckoutPolicyHolder';
import { CheckoutSeperator } from '../components/checkout/CheckoutSperator';
import { CheckoutTariffDetails } from '../components/checkout/CheckoutTariffDetails';
import Footer from '../components/Footer';
import { Headline } from '../components/Headline';
import { mapAddressesToPrintableText } from '../helpers/addressesHelper';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import './InsuranceSummaryPage.css';

export interface InsuranceSummaryPageData extends StoreStateUpdater<InsuranceSummaryPageData> {
    policyHolder: Person;
    insuredPersons: Person[];
    paymentMethod: string;
    iban?: string;
    insuranceFeeTotal: number;
    insuranceBegin: IsoDateString;
}

const InsuranceSummaryPage = (props: PagePropsWithValues<InsuranceSummaryPageData>) => {

    React.useEffect(() => {
        scrollToTop();
    }, []);

    return <>
        {renderPageHeading(props.storeState.policyHolder)}
        <CheckoutSeperator/>

        <CheckoutTariffDetails insuredPersons={props.storeState.insuredPersons}
                               paymentMethod={props.storeState.paymentMethod}
                               insuranceTotalFee={props.storeState.insuranceFeeTotal}
                               handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <CheckoutPolicyHolder policyHolder={props.storeState.policyHolder}
                              handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <CheckoutInsuredPersons insuredPersons={props.storeState.insuredPersons}
                                handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <CheckoutBankdata policyHolder={props.storeState.policyHolder}
                          iban={props.storeState.iban}
                          insuranceBegin={props.storeState.insuranceBegin}
                          handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <Footer handleAction={props.handleAction}
                nextButtonText="Vertrag online abschließen"
                onNextClick={() => props.handleAction(NavigationAction.NEXT)}
                showNavigateToOffer={true}/>
    </>;
};

const renderPageHeading = (policyHolder: Person): JSX.Element => {

    return (<Headline>
            {mapAddressesToPrintableText(policyHolder.anrede) || policyHolder.vorname} {policyHolder.nachname},
            bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.
        </Headline>
    );
};

export default InsuranceSummaryPage;

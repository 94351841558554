"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.toReadableWord = function (word) {
  return word ? word.charAt(0).toUpperCase() + word.substr(1).toLowerCase() : '';
};

exports.sanitizeIban = function (iban) {
  if (iban) {
    return iban.replace(/(\W)/g, '');
  } else {
    return '';
  }
};

exports.ibanFormatter = function (iban) {
  if (iban) {
    return iban.toUpperCase().replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  } else {
    return '';
  }
};
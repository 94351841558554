import formatter, { EURO_WITH_CENT } from './currencyFormatter';

export const feePerPaymentMethod = (fee?: number, paymentMethod?: string) => {
    return `${formatter(EURO_WITH_CENT).format(fee || 0)} / ${mapPaymentMethod(paymentMethod)}`;
};

const mapPaymentMethod = (paymentMethod?: string): string | undefined => {
    switch (paymentMethod) {
        case 'MONATLICH':
            return 'Monat';
        case 'VIERTELJAEHRLICH':
            return 'Quartal';
        case 'HALBJAEHRLICH':
            return 'Halbjahr';
        case 'JAEHRLICH':
            return 'Jahr';
        default:
            return undefined;
    }
};

export const mapPaymentMethodToText = (paymentMethod: string | undefined): string => {
    switch (paymentMethod) {
        case 'MONATLICH':
            return 'monatlich';
        case 'VIERTELJAEHRLICH':
            return 'vierteljährlich';
        case 'HALBJAEHRLICH':
            return 'halbjährlich';
        case 'JAEHRLICH':
            return 'jährlich';
        default:
            return 'n./a.';
    }
};

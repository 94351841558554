import { FormikProps, FormikValues } from 'formik';
import { Adresse, Person } from 'kfo-common';
import * as React from 'react';
import Footer, { NextButtonTyp } from '../components/Footer';
import EditView from '../components/personComponents/EditView';
import TileView from '../components/personComponents/TileView';
import { scrollToErrorByQuery } from '../components/ScrollToError';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { updatePerson } from '../services/api';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface InsuredPersonViewModel extends Person {
    viewState: ViewState;
}

export enum ViewState {
    TILE = 'TILE',
    EDIT = 'EDIT'
}

export interface InsuredPersonsPageData extends StoreStateUpdater<InsuredPersonsPageData> {
    businessId: string;
    policyHolderAddress: Adresse;
    insuredPersons: Person[];
}

interface InsuredPersonsPageProps extends PagePropsWithValues<InsuredPersonsPageData> {
    showNavigateToOffer?: boolean;
}

interface InsuredPersonsPageState {
    deselectInsuredPersons: boolean;
    insuredPersons: InsuredPersonViewModel[];
    registeredForms: Map<string, FormikProps<FormikValues>>;
    showGlobalErrorMessages: boolean;
    intendedActionAfterSaving?: NavigationAction;
    spcsErrorAddress: boolean;
}

class InsuredPersonsPage extends React.Component<InsuredPersonsPageProps, InsuredPersonsPageState> {

    constructor(props: Readonly<InsuredPersonsPageProps>) {
        super(props);
        this.state = {
            deselectInsuredPersons: false,
            spcsErrorAddress: false,
            insuredPersons: this.mapToViewModel(this.props.storeState.insuredPersons),
            registeredForms: new Map(),
            showGlobalErrorMessages: false
        };
    }

    public componentDidMount() {
        scrollToTop();
    }

    public componentDidUpdate(prevProps: Readonly<InsuredPersonsPageProps>, prevState: Readonly<InsuredPersonsPageState>): void {
        scrollToErrorByQuery(`[role="alert"]`);
        if (hasErrorInInsuredPerson() && this.state.intendedActionAfterSaving) {
            this.setState({intendedActionAfterSaving: undefined});
        }
    }

    public render() {
        return (<>
            {this.renderInsuredPersons()}
            <br/>
            {!(this.getErrorMessages().length === 0) && this.state.showGlobalErrorMessages &&
            <div style={{color: '#e80c26', fontSize: '14px', textAlign: 'center'}}>
                {this.getErrorMessages().map(element => <div>{element}</div>)}
            </div>}
            <br/>
            <Footer
                handleAction={this.props.handleAction}
                onNextClick={() => {
                    if (this.getErrorMessages().length === 0) {
                        this.props.handleAction(NavigationAction.NEXT);
                    } else {
                        if (this.hasInsuredPersonInEditState()) {
                            this.setState({intendedActionAfterSaving: NavigationAction.NEXT});
                            this.submitRegisteredForms();
                        }
                    }
                }}
                showLoadingSpinner={!!this.state.intendedActionAfterSaving}
                nextButtonType={(!this.hasInsuredPersonInEditState()) ? undefined : NextButtonTyp.DISABLED}
                showNavigateToOffer={this.props.showNavigateToOffer && !this.hasInsuredPersonInEditState()}
            />
        </>);
    }

    private submitRegisteredForms(): void {
        this.state.registeredForms.forEach(element => {
            element.submitForm();
        });
    }

    private getErrorMessages(): string[] {
        const errorMessages: string[] = [];
        if (this.hasInsuredPersonInEditState()) {
            errorMessages.push('Bitte speichern Sie die Person in Bearbeitung.');
        }
        return errorMessages;
    }

    private hasInsuredPersonInEditState(): boolean {
        return !!this.state.insuredPersons.find(insuredPerson => insuredPerson.viewState === ViewState.EDIT);
    }

    private renderInsuredPersons() {
        return <div>
            {this.state.insuredPersons.map((insuredPerson, index) => {
                if (insuredPerson.viewState === ViewState.TILE) {
                    return (
                        <TileView key={insuredPerson.personId}
                                  headline={`${index + 1}. Kind`}
                                  insuredPerson={insuredPerson}
                                  isEditable={this.isOtherPersonInEditState(insuredPerson.personId!)}
                                  editCallback={async () => {
                                      await this.editInsuredPersonByIndex(index);
                                  }}/>);
                } else {
                    return (
                        <EditView headline={`${index + 1}. Kind`}
                                  key={insuredPerson.personId}
                                  insuredPerson={insuredPerson}
                                  valueRanges={this.props.valueRanges}
                                  updateCallback={(updatedInsuredPerson: Partial<InsuredPersonViewModel>) => {
                                      const insuredPersons = [...this.state.insuredPersons];
                                      insuredPersons[index] = {...insuredPersons[index], ...updatedInsuredPerson};
                                      this.setState({insuredPersons});
                                  }}
                                  saveCallback={async changedInsuredPerson => {
                                    const { spcsAddressValidationError }: any = await updatePerson(
                                      this.props.storeState.businessId,
                                      mapModelToPDSUpdateRequest(changedInsuredPerson),
                                      changedInsuredPerson.personId!
                                    );
                                    this.setState(prevState => ({...prevState, spcsErrorAddress: spcsAddressValidationError}));
                                    const insuredPersons = [...this.state.insuredPersons];
                                    insuredPersons[index] = changedInsuredPerson;
                                    await this.updateInsuredPersonToState(insuredPersons);
                                    changedInsuredPerson.viewState = ViewState.TILE;
                                    const registeredForms = this.state.registeredForms;
                                    registeredForms.delete(changedInsuredPerson.personId!);
                                    this.setState({ registeredForms });
                                  }}
                                  policyHolder={this.props.storeState.policyHolderAddress}
                                  submitFinishedCallback={this.submitFinishedCallback}
                                  registerViewFormCallback={this.registerEditForm}
                                  showUsePolicyHolderData={true}/>);
                }
            })}
        </div>;
    }

    private editInsuredPersonByIndex(index: number): Promise<void> {
        if (!this.hasInsuredPersonInEditState()) {
            const insuredPersons = [...this.state.insuredPersons];
            insuredPersons[index].viewState = ViewState.EDIT;
            this.setState({showGlobalErrorMessages: false, insuredPersons});
        }
        scrollToErrorByQuery(`[data-component-id="edit-insured-person"]`);
        return Promise.resolve();
    }

    private isOtherPersonInEditState(personId: string): boolean {
        return !this.state.insuredPersons.find(person =>
            personId !== person.personId && person.viewState === ViewState.EDIT
        );
    }

    public registerEditForm = (pdsId: string, formikForm: FormikProps<FormikValues>) => {
        this.state.registeredForms.set(pdsId, formikForm);
    };

    public submitFinishedCallback = () => {
        if (this.state.intendedActionAfterSaving && this.getErrorMessages().length === 0) {
            this.props.handleAction(this.state.intendedActionAfterSaving);
        } else {
            this.setState({
                showGlobalErrorMessages: true,
                intendedActionAfterSaving: undefined
            });
        }
    };

    private async updateInsuredPersonToState(insuredPersonList: InsuredPersonViewModel[]) {
        const insuredPersons: Person[] = this.mapModelToInsuredPersons(insuredPersonList);

        this.setState({
            showGlobalErrorMessages: false,
            insuredPersons: this.mapToViewModel(insuredPersons, this.state.spcsErrorAddress)
        });

        this.props.storeState.update({insuredPersons});
    }

    private mapModelToInsuredPersons(insuredPersonList: InsuredPersonViewModel[]) {
        if (insuredPersonList.length === 0) {
            return [];
        }
        return insuredPersonList.map((insuredPersonModel: InsuredPersonViewModel) => {
            return {
                personId: insuredPersonModel.personId,
                anrede: insuredPersonModel.anrede,
                vorname: insuredPersonModel.vorname,
                nachname: insuredPersonModel.nachname,
                birthdate: insuredPersonModel.birthdate,
                adresse: {...insuredPersonModel.adresse}
            } as Person;
        });
    }

    private mapToViewModel(persons: Person[], error?: boolean) {
        let noPersonInEdit = true;
        return persons.map(person => {
            let viewState: ViewState = ViewState.TILE;

            if (noPersonInEdit && isIncompletePerson(person)) {
                viewState = ViewState.EDIT;
                noPersonInEdit = false;
            }
            if (error) {
                viewState = ViewState.EDIT;
            }

            return {viewState, ...person} as InsuredPersonViewModel;
        });
    }
}

const isIncompletePerson = (person: Person): boolean => {
    const address = person.adresse;
    return (!person.anrede || !person.vorname || !person.nachname || !address || !address.strasse || !address.hausnummer || !address.ort || !address.plz);
};

const mapModelToPDSUpdateRequest = (person: InsuredPersonViewModel): Person => {
    return {
        anrede: person.anrede,
        vorname: person.vorname,
        nachname: person.nachname,
        birthdate: person.birthdate,
        adresse: {...person.adresse}
    } as Person;
};

const hasErrorInInsuredPerson = () => {
    return !!document.querySelector('[role="alert"]');
};

export default InsuredPersonsPage;

import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { EditIcon } from '@eg/elements/components/Icons';
import { IsoDateString, mapToGermanDate, Person } from 'kfo-common';
import * as React from 'react';
import { mapAddressesToPrintableText } from '../../helpers/addressesHelper';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface CheckoutBankdataProps {
    policyHolder: Person;
    iban?: string;
    insuranceBegin: IsoDateString;
    handleAction: HandleActionCallback;
}

export const CheckoutBankdata = (props: CheckoutBankdataProps) => {

    const policyHolder = props.policyHolder;

    return (<div style={{textAlign: 'center'}}>
            <h3 style={{margin: '0 0 3em 0'}} data-component-id="checkout-banking-header">Bankverbindung</h3>
            <Card padding="normal" id="bank-data" style={{textAlign: 'left'}}>
                <div>
                    {policyHolder && <div style={{borderTopWidth: '10px', borderBottomWidth: '0px', lineHeight: '20px', margin: '1em 0'}}>
                        <b data-component-id="checkout-banking-owner">{mapAddressesToPrintableText(policyHolder.anrede)}&nbsp;
                            {policyHolder.vorname}&nbsp;
                            {policyHolder.nachname}</b>&nbsp;
                    </div>
                    }
                    <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                        <small>
                            <b>IBAN: </b>
                            <span data-component-id="checkout-banking-iban">{props.iban}</span>
                        </small>
                    </div>
                    <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                        {props.insuranceBegin &&
                        <small>
                            <b>Mandatsdatum: </b>
                            <span data-component-id="checkout-banking-mandante-date">{mapToGermanDate(props.insuranceBegin)}</span>
                        </small>}
                    </div>
                </div>
            </Card>
            <Button width="auto" variant="text-link" size="large" style={{fontWeight: 'bold'}} iconLeft={EditIcon}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Link_BankverbindungAendern);
                        props.handleAction(NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE);
                    }}
                    data-component-id="checkout-banking-edit-button">
                ändern
            </Button>
        </div>
    );
};

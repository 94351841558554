import axios from 'axios';
import {
    AngebotResponse,
    CreateRequest,
    CreateResponse,
    ErrorLogKey,
    ErrorRequest,
    IbanServiceResponse,
    MESSAGE_MAX_STRING_SIZE,
    OfferType,
    Person,
    ResponseCodes,
    UpdateRequest
} from 'kfo-common';
import { SpcsPersonResponse } from '../../../backend/src/types/SpcsPersonResponse';
import { OrderRequest } from '../../../common/src/types';
import { CONFIG_BACKEND_BASE_URL } from '../config';
import { BankingDetailsPageData } from '../pages/BankingDetailsPage';
import { BirthdatePageData } from '../pages/BirthdatePage';
import { FeePageData } from '../pages/FeePage';
import { InsuranceBeginPageData } from '../pages/InsuranceBeginPage';
import { addTrackingData } from '../tracking/tracker';

const headers = {headers: {'Cache-Control': 'no-cache', Pragma: 'no-cache'}};
axios.interceptors.request.use(function(config) {
    const token = sessionStorage.getItem('kfoJwt');

    if (token !== undefined) {
        config.headers.Authorization = token;
    }

    return config;
}, function(err) {
    return Promise.reject(err);
});

export const getOfferPageData = async (businessId: string, page: string): Promise<AngebotResponse> => {
    const endpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}/${page}`;

    try {
        const response = await axios.get(endpoint, headers);
        return Promise.resolve(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const createAngebot = async (req: CreateRequest): Promise<CreateResponse> => {
    const endpoint = `${CONFIG_BACKEND_BASE_URL}/angebot`;
    try {
        const response = await axios.post(endpoint, req, headers);
        sessionStorage.setItem('kfoJwt', response.headers.authorization);

        return Promise.resolve(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const log = (errorLogKey: ErrorLogKey, msg?: string): void => {
    const endpoint = `${CONFIG_BACKEND_BASE_URL}/log`;
    const maxMessageSize = MESSAGE_MAX_STRING_SIZE - 1;
    let message = msg;
    if (message && message.length > maxMessageSize) {
        message = message.substring(0, maxMessageSize); // bissel was ist besser als nichts
    }
    const reqBody: ErrorRequest = {
        errorLogKey,
        message
    };
    try {
        axios.put(endpoint, reqBody, headers).catch(() => {
            // Errors form send log information are dull
        });
    } catch (e) {
        // Errors form send log information are dull
    }
};

export const validateIban = async (iban: string): Promise<IbanServiceResponse> => {
    const endpoint = `${CONFIG_BACKEND_BASE_URL}/ibanService`;
    const body = {
        iban
    };

    try {
        const response = await axios.put(endpoint, body, headers);
        return Promise.resolve(response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updateBirthdatePageData = async (businessId: string, values: Partial<BirthdatePageData>): Promise<AngebotResponse> => {
    const body: UpdateRequest = {
        insuredPersons: values.insuredPersons
    };

    return callEndpoint(businessId, body, 'birthdates');
};

export const updateBankingDetailsPageData = async (businessId: string, values: Partial<BankingDetailsPageData>): Promise<AngebotResponse> => {
    const body: UpdateRequest = {
        iban: values.iban,
        bankAccountOwner: `${values.vorname} ${values.nachname}`
    };

    return callEndpoint(businessId, body, 'bankingDetails');
};

export const updateFeePage = async (businessId: string, values: Partial<FeePageData>): Promise<AngebotResponse> => {
    const body: UpdateRequest = {
        paymentMethod: values.paymentMethod
    };
    const response = await callEndpoint(businessId, body, 'fee');
    addTrackingData({
        insuranceFeeTotal: response.insuranceFeeTotal,
        paymentMethod: response.paymentMethod
    });
    return response;
};

export const updateInsuranceBeginPageData = async (businessId: string, values: Partial<InsuranceBeginPageData>): Promise<AngebotResponse> => {
    const body: UpdateRequest = {
        insuranceBegin: values.insuranceBegin
    };

    return callEndpoint(businessId, body, 'insuranceBegin');
};

export const updateInsuredPersonsPageData = (businessId: string): Promise<AngebotResponse> => {
    return callEndpoint(businessId, {}, 'insuredPersons');
};

export const updatePolicyHolderPageData = async (businessId: string): Promise<AngebotResponse> => {
    return callEndpoint(businessId, {}, 'policyHolder');
};

const callEndpoint = async (businessId: string, body: UpdateRequest, endpoint: string): Promise<AngebotResponse> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/angebot/${businessId}/${endpoint}`;

    addTrackingData(body);

    try {
        return axios.put(serviceEndpoint, body, headers).then(response => response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const updatePerson = async (businessId: string, body: Person, personId: string): Promise<SpcsPersonResponse> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

    try {
        return axios.put(serviceEndpoint, body, headers).then(response => response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const getPerson = async (businessId: string, personId: string): Promise<Person> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

    try {
        return axios.get(serviceEndpoint, headers).then(response => response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const putPerson = (businessId: string, body: Partial<Person>, personId: string): Promise<void> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/person/${businessId}/${personId}`;

    try {
        return axios.put(serviceEndpoint, body, headers).then(response => response.data);
    } catch (error) {
        return Promise.reject(error);
    }
};

export const makeOrder = async (businessId: string): Promise<number> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/order/${businessId}`;
    try {
        return axios.post(serviceEndpoint, {}, headers).then(response => response.status);
    } catch (error) {
        if (error.response.status === ResponseCodes.PRECONDITION_FAILED) {
            return error.response.status;
        }
        return Promise.reject(ResponseCodes.ERROR);
    }
};

export const makeOffer = async (businessId: string, offerType: OfferType): Promise<AngebotResponse> => {
    const serviceEndpoint = `${CONFIG_BACKEND_BASE_URL}/order/${businessId}`;
    const reqBody: OrderRequest = {offerType};

    addTrackingData(reqBody);

    try {
        return axios.post(serviceEndpoint, reqBody, headers).then(response => response.data);
    } catch (error) {
        return Promise.reject(error);
    }

};

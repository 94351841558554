import * as React from 'react';
import FeedbackPage, { FeedbackPageData } from '../../pages/FeedbackPage';
import { getOfferPageData } from '../../services/api';
import { StateDefinition } from '../StateMachine';
import { StateName } from '../StateMachineTypes';

export const feedBackPage: StateDefinition<FeedbackPageData> = {
    name: StateName.FEED_BACK_PAGE,
    transitions: [],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'feedback');

        return {
            userInput: {
                anrede: response.policyHolder ? response.policyHolder.anrede : undefined,
                vorname: response.policyHolder ? response.policyHolder.vorname : '',
                nachname: response.policyHolder ? response.policyHolder.nachname : '',
                policyHolderId: response.policyHolder ? response.policyHolder.personId : '',
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <FeedbackPage
            storeState={{
                anrede: inputData.userInput.anrede,
                vorname: inputData.userInput.vorname,
                nachname: inputData.userInput.nachname,
                businessId: inputData.businessId,
                policyHolderId: inputData.userInput.policyHolderId,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

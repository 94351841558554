import * as React from 'react';
import InsuranceBeginPage, { InsuranceBeginPageData } from '../../pages/InsuranceBeginPage';
import { getOfferPageData, updateInsuranceBeginPageData } from '../../services/api';
import { addTrackingData } from '../../tracking/tracker';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuranceBeginPage: StateDefinition<InsuranceBeginPageData> = {
    name: StateName.INSURANCE_BEGIN_PAGE,
    transitions: [{
        test: (action, data) => action === NavigationAction.NEXT,
        newState: StateName.FEE_PAGE
    },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.BIRTHDATE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceBegin');

        return {
            userInput: {
                insuranceBegin: response.insuranceBegin,
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        const response = await updateInsuranceBeginPageData(inputData.businessId, inputData.userInput);
        addTrackingData({
            insuranceFeeTotal: response.insuranceFeeTotal,
            paymentMethod: response.paymentMethod
        });
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuranceBeginPage
            storeState={{
                insuredPersons: inputData.userInput.insuredPersons,
                insuranceBegin: inputData.userInput.insuranceBegin,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

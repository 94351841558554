import Button from '@eg/elements/Button';
import { CheckIllustratedIcon } from '@eg/elements/components/Icons';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import Modal from '@eg/elements/Modal';
import Price from '@eg/elements/Price';
import TooltipIcon from '@eg/elements/TooltipIcon';
import { Form, Formik } from 'formik';
import { IsoDateString, mapToGermanDate, Person, PersonalizedFee, TextValue, ValueRanges } from 'kfo-common';
import * as React from 'react';
import Footer from '../components/Footer';
import { Headline } from '../components/Headline';
import formatter, { EURO_WITH_CENT } from '../helpers/currencyFormatter';
import { mapPaymentMethodToText } from '../helpers/paymentHelper';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { updateFeePage } from '../services/api';
import { trackElementClicked, trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import './FeePage.css';

export interface FeePageData extends StoreStateUpdater<FeePageData> {
    businessId: string;
    insuranceFeeTotal: number;
    insuranceBegin: IsoDateString;
    paymentMethod: string; // Todo insert enum
    insuredPersons: Person[];
    personalizedFee: PersonalizedFee[];
}

const FeePage = (props: PagePropsWithValues<FeePageData>) => {

    React.useEffect(() => {
        scrollToTop();
    }, []);

    const [isLoading, setLoading] = React.useState(false);

    return (<Formik
            initialValues={props.storeState}
            onSubmit={() => {
                props.handleAction(NavigationAction.NEXT);
            }}
        >
            {form => (
                <Form>
                    <h3 className="feepage-header feepage-center-text">Ihr Zahlbeitrag gesamt</h3>

                    <div className="feepage-price">
                        <Price data-component-id="fee-page-price" value={isLoading ? undefined : props.storeState.insuranceFeeTotal}
                               suffix={mapPaymentMethodToText(props.storeState.paymentMethod)}/>
                        <LoadingSpinner viewport="relative" show={isLoading}/>
                    </div>
                    {SummaryLine(props)}
                    {PaymentMethod(props, setLoading)}
                    <Headline>Die Leistungen für {props.storeState.insuredPersons.length > 1
                        ? `Ihre ${props.storeState.insuredPersons.length} Kinder` :
                        `Ihr Kind`} &ndash; ohne Wartezeit:</Headline>
                    <TarifDetails/>
                    <Footer handleAction={props.handleAction}
                            showConsultingInfo={true}
                            onNextClick={() => undefined}
                            showNavigateToOffer={true}/>
                </Form>
            )}
        </Formik>
    );
};

const SummaryLine = (props: PagePropsWithValues<FeePageData>): JSX.Element => {

    const multipleInsuredPersons = props.storeState.insuredPersons ? props.storeState.insuredPersons.length > 1 : false;

    return (<>
        <div className="esc_grid">
            <div className="esc_grid__wrapper feepage-wrapper feepage-center-text">
                <div className="esc_col esc_col-12 esc_col-m-10 feepage-col">
                    <div>
                    <span data-component-id="childCount">
                        Versichert {multipleInsuredPersons ? 'sind ' : 'ist '}
                        <span className="feepage-details-keyword feepage-bold-font">
                             {multipleInsuredPersons ? `${props.storeState.insuredPersons.length} Kinder` : 'ein Kind'}
                        </span>
                    </span>
                        {` ab dem `}
                        <span data-component-id="insurance-Start" className="feepage-details-keyword feepage-bold-font">
                            {mapToGermanDate(props.storeState.insuranceBegin)}
                        </span>
                        {`.`}
                    </div>
                    {multipleInsuredPersons && PricingToolTip(props)}
                </div>
            </div>
        </div>
        <div className="feepage-center-text">
            <Button type="button" className="feepage-bold-font" variant={'text-link'} onClick={() => {
                trackElementClicked(TrackingElement.Button_TarifdatenAendern);
                props.handleAction(NavigationAction.DIRECT_JUMP_TARRIF_DATA);
            }}>Angaben ändern</Button>
        </div>
    </>);
};

const PaymentMethod = (props: PagePropsWithValues<FeePageData>, setLoading: React.Dispatch<React.SetStateAction<boolean>>): JSX.Element => {
    return <div className="feepage-center-text feepage-zahlweise" data-component-id="fee-page-payment-methods">
        <b>Zahlweise:</b>
        <div className="feepage-center-text feepage-zahlweise-buttons">
            {props.valueRanges.paymentMethod
            && props.valueRanges.paymentMethod.possibleValues
            && props.valueRanges.paymentMethod.possibleValues.map(method => {
                const variante = method === props.storeState.paymentMethod ? 'primary' : 'tertiary';

                return <Button
                    key={method}
                    type="button"
                    data-component-id={`paymentmethod-${method}-button`}
                    variant={variante}
                    className="feepage-zahlweise-button"
                    width="auto"
                    onClick={async () => {
                        setLoading(true);
                        const response = await updateFeePage(props.storeState.businessId, {
                            paymentMethod: method
                        });
                        props.storeState.update({
                            insuranceBegin: response.insuranceBegin,
                            insuranceFeeTotal: response.insuranceFeeTotal,
                            paymentMethod: response.paymentMethod,
                            insuredPersons: response.insuredPersons,
                            personalizedFee: response.personalizedFee,
                            messages: response.messages
                        }, () => setLoading(false));
                    }}
                >
                    {mapPaymentMethodToText(method)}
                </Button>;
            })}
        </div>
    </div>;
};

const PricingToolTip = (props: PagePropsWithValues<FeePageData>): JSX.Element => {
    const readablePaymentmethod: string = mapPaymentMethodToText(props.storeState.paymentMethod);

    return (
        <TooltipIcon data-component-id="pricing-tooltip"
                     onToggledOpen={(isOpen: boolean) => {
                         if (isOpen) {
                             trackElementClickImmediate(TrackingElement.Icon_InfoTarifdaten); }
                         }
                     }>
            <b>Ihr Beitrag beträgt</b>
            <div className="feepage-pricing-tooltip">
                {PricingCalculation(props.storeState)}
            </div>
            {DetailedPricingInfo(props.valueRanges, readablePaymentmethod)}
        </TooltipIcon>);
};

const PricingCalculation = (storeState: FeePageData): JSX.Element[] => {
    const pricePoints: JSX.Element[] = [];

    storeState.personalizedFee.forEach((personWithFee, index) => {
        const matchingPerson: Person | undefined = storeState.insuredPersons.find(person => personWithFee.personId === person.personId);

        if (matchingPerson) {
            pricePoints.push(<span className="feepage-pricing-calculation">
                <br/>{`für Ihr ${index + 1}. Kind, `}
                <span className="feepage-pricing-small-text">{mapToGermanDate(matchingPerson.birthdate)}</span>
                <span className="feepage-pricing-float">
                    {`${formatter(EURO_WITH_CENT).format(personWithFee.fee)} `}
                    <span className="feepage-pricing-small-text">
                        {mapPaymentMethodToText(storeState.paymentMethod)}
                    </span>
                </span>

            </span>);
        }
    });
    return pricePoints;
};

const DetailedPricingInfo = (ranges: ValueRanges, paymentMethod: string): JSX.Element => {
    return <div className="feepage-detailed-pricing">
        {ranges.pricing.possibleValues.map((priceOption: TextValue) => {
            const formattedPrice = formatter(EURO_WITH_CENT).format(Number(priceOption.text));
            return <div>{priceOption.key}<b>{`${formattedPrice} monatlich`}</b></div>;
        })}
    </div>;
};

function getModalContent() {
    return <div data-component-id="insurance-features-modal-content" className="esc_simple-table">
        <table>
            <thead>
                <tr>
                    <th scope="col">
                        <h3 className="modal-header">ERGO Kieferorthopädie Sofortschutz für Kinder (KFO)</h3>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <p>
                            Zusammen mit der Vorleistung der gesetzlichen Krankenversicherung (GKV) sowie den
                            Erstattungen Dritter erhalten Sie maximal die erstattungsfähigen Aufwendungen.
                            ERGO leistet bei kieferorthopädischen Maßnahmen nicht für den selbst zu zahlenden Eigenanteil,
                            den die GKV nach erfolgreich abgeschlossener Behandlung erstattet.
                            Unter „Mehrkosten“ fallen private Mehr- und Zusatzleistungen.
                            <TooltipIcon className="modal-tooltip modal-first-tooltip">
                                <p>Aufwendungen sind erstattungsfähig, wenn sie</p>
                                <ul>
                                    <li>innerhalb des in der GOÄ/GOZ festgelegten Gebührenrahmens liegen sowie</li>
                                    <li>den dortigen Vorschriften entsprechen.</li>
                                </ul>
                            </TooltipIcon>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungen für Kieferorthopädie:</h3>
                        <TooltipIcon className="modal-tooltip">
                            <p>ERGO leistet unter diesen Voraussetzungen:</p>
                            <ul>
                                <li>Die Maßnahmen sind medizinisch notwendig</li>
                                <li>Die Behandlung hat vor dem 18. Geburtstag begonnen.</li>
                            </ul>
                        </TooltipIcon>
                        <p>
                            Angeratene Behandlungen sind mitversichert. Wenn die Behandlung bei Vertragsbeginn bereits
                            läuft, gilt: ERGO übernimmt die Kosten, die nach dem Vertragsbeginn anfallen:
                        </p>
                        <ul>
                            <li>50 % der privaten Mehrkosten nach Vorleistung der gesetzlichen Krankenversicherung oder sogar</li>
                            <li>75 %, falls innerhalb der ersten 4 Versicherungsjahre keine kieferorthopädische Behandlung durchgeführt wurde oder</li>
                            <li>250 € pro Versicherungsjahr, wenn die gesetzliche Krankenversicherung nicht leistet.</li>
                        </ul>
                        <p>Erstattungsfähige Aufwendungen sind z. B.:</p>
                        <ul>
                            <li>Eingliederung von thermo- oder superelastischen Bögen</li>
                            <li>Eingliederung festsitzender Apparaturen (z. B. Pendulum)</li>
                            <li>Einsatz festsitzender Retainer</li>
                        </ul>
                        <p>Außerdem damit zusammenhängende Maßnahmen wie z. B.:</p>
                        <ul>
                            <li>Funktionsanalyse</li>
                            <li>Funktionstherapie</li>
                            <li>Bracketumfeldversiegelungen</li>
                            <li>Laborarbeiten und Materialien</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungen für Schmerzausschaltung bzw. Schmerztherapie:</h3>
                        <TooltipIcon className="modal-tooltip">
                            <p>
                                Voraussetzung: Die Maßnahmen stehen im Zusammenhang mit einer medizinisch notwendigen
                                zahnärztlichen, kieferorthopädischen oder kieferchirurgischen Behandlung.
                            </p>
                        </TooltipIcon>
                        <p>
                            100 % für Schmerzausschaltung:
                        </p>
                        <ul>
                            <li>Narkose (Vollnarkose, Sedierung)</li>
                            <li>Akupunktur</li>
                            <li>Hypnose</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungen für Zahnersatzmaßnahmen:</h3>
                        <TooltipIcon className="modal-tooltip">
                            <p>ERGO leistet unter diesen Voraussetzungen:</p>
                            <ul>
                                <li>Die Maßnahmen sind medizinisch notwendig.</li>
                                <li>Die Maßnahmen wurden nach Vertragsbeginn erstmals angeraten und durchgeführt.</li>
                            </ul>
                            <p>Nicht versichert sind Zähne, die bei Vertragsschluss fehlten und noch nicht dauerhaft ersetzt wurden.</p>
                        </TooltipIcon>
                        <p>
                            100 % für Zahnersatz wie z. B.:
                        </p>
                        <ul>
                            <li>Kronen</li>
                            <li>Brücken</li>
                            <li>Prothesen</li>
                            <li>Implantatgetragener Zahnersatz</li>
                            <li>Implantate</li>
                        </ul>
                        <p>
                            100 % für damit zusammenhängende Maßnahmen wie z. B.:
                        </p>
                        <ul>
                            <li>Reparaturen</li>
                            <li>Eingliederung von Provisorien, Aufbissbehelfen und Schienen</li>
                            <li>Funktionsanalyse</li>
                            <li>Funktionstherapie</li>
                            <li>Verblendungen</li>
                            <li>Laborarbeiten und Materialien</li>
                        </ul>
                        <p>Erbringt die gesetzliche Krankenversicherung (GKV) die vorgesehene Leistung nicht, werden als
                            Vorleistung der GKV pauschal 35 % der erstattungsfähigen Aufwendungen angerechnet.</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungen für Zahnerhaltmaßnahmen:</h3>
                        <p>
                            100 % für Zahnerhalt wie z. B.:
                        </p>
                        <ul>
                            <li>Kunststofffüllungen</li>
                            <li>Einlagenfüllungen (Inlays, Onlays)</li>
                            <li>Knirscherschienen</li>
                            <li>Wurzelbehandlung</li>
                            <li>Parodontosebehandlungen</li>
                        </ul>
                        <p>
                            100 % für dazugehörige Maßnahmen wie z. B.:
                        </p>
                        <ul>
                            <li>Funktionsanalyse</li>
                            <li>Funktionstherapie</li>
                            <li>Laborarbeiten und Materialien</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungen für zahnmedizinische Individualprophylaxe:</h3>
                        <p>
                            100 % für zahnmedizinische Individualprophylaxe wie z. B.:
                        </p>
                        <ul>
                            <li>Professionelle Zahnreinigung</li>
                            <li>Mundhygienestatus</li>
                            <li>Aufklärung über Ursachen und Vermeidung von Zahnkrankheiten</li>
                            <li>Fluoridierung zur Zahnschmelzhärtung</li>
                            <li>Beseitigung von Zahnbelägen und Verfärbungen</li>
                            <li>Behandlung von überempfindlichen Zahnflächen</li>
                            <li>Fissurenversiegelung</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>
                        <h3 className="modal-section-header">Leistungsbegrenzungen in den ersten 4 Versicherungsjahren für Maßnahmen zu Zahnersatz,
                            Zahnerhalt und Prophylaxe sowie für Schmerzausschaltung:</h3>
                        <p>
                            Bei einem Unfall besteht sofort Anspruch auf die Tarifleistungen in voller Höhe.
                        </p>
                        <p>
                            Ansonsten gelten je versicherter Person folgende Leistungsbegrenzungen:
                        </p>
                        <ul>
                            <li>Im ersten Versicherungsjahr höchstens 500 €</li>
                            <li>In den ersten beiden Versicherungsjahren zusammen höchstens 1.000 €</li>
                            <li>In den ersten 3 Versicherungsjahren zusammen höchstens 1.500 €</li>
                            <li>In den ersten 4 Versicherungsjahren zusammen höchstens 2.000 €</li>
                        </ul>
                        <p>Nach dem vierten Versicherungsjahr fallen diese Begrenzungen weg.</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>;
}

const TarifDetails = (): JSX.Element => {
    const ICON_WIDTH = 32;
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    return (<div className="esc_grid details-grid">
        <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-2 esc_col-m-1">
                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} className="details-auto-margin"/></div>
            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                50 % Sofortleistung bei Kieferorthopädie, wenn die gesetzliche Krankenkasse leistet. Angeratene Behandlungen sind mitversichert.
            </div>
            <div className="esc_col esc_col-2 esc_col-m-1">
                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} className="details-auto-margin"/></div>
            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                Die Sofortleistung erhöht sich auf 75 %, wenn innerhalb der ersten 4 Versicherungsjahre keine Behandlung durchgeführt wurde.
            </div>
            <div className="esc_col esc_col-2 esc_col-m-1">
                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} className="details-auto-margin"/></div>
            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                Oder es gibt 250 € pro Versicherungsjahr, falls die gesetzliche Krankenkasse nicht leistet.
            </div>
            <div className="esc_col esc_col-2 esc_col-m-1">
                <CheckIllustratedIcon height={ICON_WIDTH} width={ICON_WIDTH} className="details-auto-margin"/></div>
            <div className="esc_col esc_col-10 esc_col-m-11 grid-cell-vertical-centered">
                100 % bei Zahnersatz, Zahnerhalt und Schmerzausschaltung.
            </div>
            <div className="esc_col esc_col-12 feepage-center-text">
                <Button variant={'text-link'}
                        type="button" data-component-id="leistungen-button"
                        className="details-bottom-margin"
                        onClick={() => {
                            trackElementClickImmediate(TrackingElement.Link_LeistungenAnzeigen);
                            setModalVisible(true);
                        }}>
                    Alle Leistungen
                </Button>
                <Modal
                    open={modalVisible}
                    dismissible
                    onDismiss={() => setModalVisible(false)}
                    backdropDismissesModal
                    data-component-id="insurance-features-modal"
                >
                    {getModalContent()}
                </Modal>
            </div>
        </div>
    </div>);
};

export default FeePage;

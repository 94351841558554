import FormFooter from '@eg/elements/FormFooter';
import LayoutBox from '@eg/elements/LayoutBox';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { CreateRequest, Message } from 'kfo-common';
import * as React from 'react';
import { getAgencyParams } from '../helpers/agencyParamsExtractor';
import { getConfigVariable } from '../helpers/configurableVariables';
import { SESSION_STORAGE_BUSINESS_ID_KEY, Storage } from '../helpers/Storage';
import { stateMachineStates } from '../routing/routes';
import StateMachine from '../routing/StateMachine';
import { StateName } from '../routing/StateMachineTypes';
import { createAngebot } from '../services/api';
import { trackPageTransition } from '../tracking/tracker';
import './App.css';
import ErrorModal from './ErrorModal';

export interface AppProps {}

export interface AppState {
  businessId?: string;
  oeNumber?: string;
  quellsystem?: string;
  isUpdateCalling: boolean;
  isInitFetchError: boolean;
  messages?: Message[];
}

export class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      isUpdateCalling: false,
      isInitFetchError: false,
      quellsystem: getConfigVariable('quellsystem') as string
    };
  }

  public async componentDidMount() {
    await this.initFromSessionStorage();
  }

  public componentDidUpdate(prevProps: AppProps, prevState: AppState) {
    if (
      this.state.businessId &&
      prevState.businessId !== this.state.businessId
    ) {
      Storage.writeItem(
        SESSION_STORAGE_BUSINESS_ID_KEY,
        String(this.state.businessId)
      );
    }
  }

  public render() {
    return (
      <LayoutBox direction="column">
        <LoadingSpinner show={this.state.isUpdateCalling} />
        <ErrorModal
          onClick={this.initAngebot}
          open={this.state.isInitFetchError}
        />
        {this.renderMainComponent()}
        <div style={{ marginTop: '4em' }}>
          <FormFooter />
        </div>
      </LayoutBox>
    );
  }

  private async initFromSessionStorage() {
    const businessId = Storage.readItem(SESSION_STORAGE_BUSINESS_ID_KEY);
    if (businessId && businessId !== 'undefined') {
      this.setState({
        businessId
      });
    } else {
      await this.initAngebot();
      trackPageTransition(StateName.BIRTHDATE_PAGE);
    }
  }

  private readonly initAngebot = async () => {
    try {
      this.setState({
        isUpdateCalling: true,
        isInitFetchError: false
      });

      const params = getAgencyParams();
      console.info(`Init ${params.channelType} offer`);
      const req: CreateRequest = {
        oeNumber: params.oeNummer,
        aktionsnummer: params.aktionsnummer,
        quellsystem: this.state.quellsystem,
        vermittlernummer: params.vermittlernummer,
        fremdId: params.fremdId
      };
      const data = await createAngebot(req);

      if (data) {
        this.setState({
          businessId: data.businessId,
          messages: data.messages
        });
      }
    } catch (error) {
      console.error(
        'an error occured while creating an offer -',
        JSON.stringify(error, undefined, '    ')
      );
      this.setState({
        isUpdateCalling: false,
        isInitFetchError: true
      });
    } finally {
      this.setState({
        isUpdateCalling: false
      });
    }
  };

  private renderMainComponent(): JSX.Element | undefined {
    const { businessId } = this.state;
    if (!businessId) {
      return undefined;
    }

    return (
      <div data-cat-id={this.state.businessId}>
        <StateMachine
          stateDefinitions={stateMachineStates}
          inputData={{
            businessId
          }}
        />
      </div>
    );
  }
}

export default App;

import * as React from 'react';
import PolicyHolderPage, { PolicyHolderPageData } from '../../../pages/PolicyHolderPage';
import { getOfferPageData, updatePolicyHolderPageData } from '../../../services/api';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const requestOfferPolicyHolderPage: StateDefinition<PolicyHolderPageData> = {
    name: StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
        transitions: [{
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.FEE_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'policyHolder');

        return {
            userInput: {policyHolder: response.policyHolder},
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        await updatePolicyHolderPageData(inputData.businessId);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <PolicyHolderPage
            storeState={{
                policyHolder: inputData.userInput.policyHolder,
                messages: inputData.userInput.messages,
                update: (values, callback) => updateApp(values, callback)
            }}
            businessId={inputData.businessId}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

import Button from '@eg/elements/Button';
import Modal from '@eg/elements/components/Modal';
import * as React from 'react';

export interface ErrorModalProps {
    onClick: () => void;
    open: boolean;
}

const ErrorModal = (props: ErrorModalProps) => {
    return (
        <Modal open={props.open} data-component-id="error-modal">
            <div>
                <p>
                    <b data-component-id="error-modal-header">Ups, da ist wohl etwas schief gegangen!</b>
                    <br/>
                    Ihre Aktion konnte nicht ausgeführt werden. Bitte stellen Sie sicher, dass eine Internetverbindung besteht.
                    Versuchen Sie es dann noch einmal oder nutzen Sie unseren telefonischen Kontakt.
                    <br/>
                    <br/>
                    <Button onClick={props.onClick} data-component-id="error-modal-retry-button"
                            size="large">
                        Erneut versuchen
                    </Button>
                </p>
            </div>
        </Modal>
    );
};

export default ErrorModal;

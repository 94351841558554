import * as React from 'react';
import RequestOfferFeedbackPage, { RequestOfferFeedbackPageData } from '../../../pages/RequestOfferPages/RequestOfferFeedbackPage';
import { getOfferPageData } from '../../../services/api';
import { StateDefinition } from '../../StateMachine';
import { StateName } from '../../StateMachineTypes';

export const requestofferFeedBackPage: StateDefinition<RequestOfferFeedbackPageData> = {
    name: StateName.REQUEST_OFFER_FEED_BACK_PAGE,
    transitions: [],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'requestOfferFeedback');

        return {
            userInput: {
                anrede: response.policyHolder ? response.policyHolder.anrede : undefined,
                vorname: response.policyHolder ? response.policyHolder.vorname : '',
                nachname: response.policyHolder ? response.policyHolder.nachname : '',
                shouldDownloadPdf: response.shouldDownloadPdf,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <RequestOfferFeedbackPage
            businessId={inputData.businessId}
            storeState={{
                anrede: inputData.userInput.anrede,
                vorname: inputData.userInput.vorname,
                nachname: inputData.userInput.nachname,
                shouldDownloadPdf: inputData.userInput.shouldDownloadPdf,

                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

import { IsoDateString, mapToGermanDate, parseDate, Person, ValueRanges } from 'kfo-common';
import * as React from 'react';
import { NumericRange } from '../../../common/src/types';
import Footer, { NextButtonTyp } from '../components/Footer';
import { Headline } from '../components/Headline';
import { scrollToTop } from '../helpers/scrolling';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { isDateOlderThan } from '../validation/date';
import './InsuranceBeginPage.css';

export interface InsuranceBeginPageData extends StoreStateUpdater<InsuranceBeginPageData> {
    insuranceBegin: IsoDateString;
    insuredPersons: Person[];
}

type UpdateInsuranceBeginCallback = (values: Partial<InsuranceBeginPageData>, callback?: () => void) => void;

const InsuranceBeginPage = (props: PagePropsWithValues<InsuranceBeginPageData>) => {

    React.useEffect(() => {
        scrollToTop();
    }, []);

    return (<>
        <Headline>
            Wann soll die Versicherung beginnen?
        </Headline>
        <div style={{
            textAlign: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1em',
            marginBottom: '2em'
        }}>
            {renderInsuranceSelection(props.storeState.insuredPersons, props.valueRanges, props.storeState.update, props.handleAction)}
        </div>
        <Footer handleAction={props.handleAction}
                nextButtonType={NextButtonTyp.DISABLED}
        />
    </>);

};

const renderInsuranceSelection = (insuredPersons: Person[], valueRanges: ValueRanges, updateInsuranceBeginCallback: UpdateInsuranceBeginCallback,
                                  handleAction: HandleActionCallback): JSX.Element => {
    const insuranceSelection: JSX.Element[] = [];
    let previousElementNotSelectable: boolean | undefined;
    let restrictionMessage: string | undefined;
    const birthdates = insuredPersons.map(ip => ip.birthdate).sort();
    const oldestBirthdate = birthdates[0];

    valueRanges.insuranceStart.possibleValues.forEach((insuranceBegin: IsoDateString, index: number) => {
        const isNotSelectable = !!getRestrictionMessage(oldestBirthdate, insuranceBegin, valueRanges.ageAtEntry);
        restrictionMessage = restrictionMessage || getRestrictionMessage(oldestBirthdate, insuranceBegin, valueRanges.ageAtEntry);

        if (previousElementNotSelectable !== undefined) {
            if (previousElementNotSelectable !== isNotSelectable) {
                insuranceSelection.push(React.createElement('div', {
                    'data-component-id': 'restrictionMessage',
                    className: 'insurancebegin-restriction-message'
                }, restrictionMessage));
            } else {
                insuranceSelection.push(React.createElement('div', {className: 'insurancebegin-restriction-spacer'}));
            }
        }

        insuranceSelection.push(<div
            className="ee_button ee_button--regular ee_button--tertiary ee_button--size-large kfo-custom-button"
            data-disabled={isNotSelectable}
            data-component-id={`insuranceBegin-${index}`}
            onClick={async () => {
                if (!isNotSelectable) {
                    updateInsuranceBeginCallback({insuranceBegin}, () => {
                        trackElementClicked(TrackingElement.Button_Weiter);
                        handleAction(NavigationAction.NEXT);
                    });
                }
            }}>
            <span className="ee_button__wrapper" data-component-id={`insuranceBegin-${index}-content-wrapper`}>
                <span className="ee_button__content">{mapToGermanDate(insuranceBegin)}</span>
            </span>
        </div>);

        previousElementNotSelectable = isNotSelectable;
    });
    return React.createElement('div', {style: {maxWidt: '80%'}}, ...insuranceSelection);
};

const getRestrictionMessage = (oldestPerson: IsoDateString | undefined, insuranceStart: IsoDateString, ageAtEntry: NumericRange): string | undefined => {
    const insuranceBegin = parseDate(insuranceStart);
    if (insuranceBegin && oldestPerson) {
        const isTooOld = isDateOlderThan(insuranceBegin, oldestPerson, ageAtEntry.max + 1);
        if (isTooOld) {
            return `Das Höchstalter für versicherte Personen ist ${ageAtEntry.max} Jahre.`;
        }
    }
    return undefined;
};

export default InsuranceBeginPage;

import { SESSION_STORAGE_OFFER_BACK_STATE, Storage } from '../helpers/Storage';
import { StateDefinition, TransitionDetails } from './StateMachine';
import { NavigationAction, REQUEST_OFFER_PAGES, StateName } from './StateMachineTypes';

export const writeOfferBackJumpMarker = (transitionDetails: TransitionDetails) => {
    if (REQUEST_OFFER_PAGES.find(s => s === transitionDetails.targetStateName)) {
        Storage.writeItem(SESSION_STORAGE_OFFER_BACK_STATE, transitionDetails.sourceStateName);
    }
};

export const createOfferNavigation = (defaultState: StateName, validInboundStates: StateName[]) => {
    // tslint:disable-next-line:no-any
    return (action: NavigationAction, inputData: any): StateName => {
        const navigation = Storage.readItem(SESSION_STORAGE_OFFER_BACK_STATE);
        if (navigation && validInboundStates.find(s => s === navigation)) {
            return navigation as StateName;
        }
        return defaultState;
    };
};

// tslint:disable-next-line:no-any
export const resolveNewStateName = (currentState: StateDefinition<any>, action: NavigationAction, inputData: any): StateName | undefined => {
    for (const transition of currentState.transitions) {
        if (transition.test(action, inputData)) {
            return transition.resolveNewState ? transition.resolveNewState(action, inputData.userInput) : transition.newState;
        }
    }
    return undefined;
};

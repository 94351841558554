import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { Form, Formik, FormikActions } from 'formik';
import { DateObject, Person } from 'kfo-common';
import * as React from 'react';
import Footer from '../components/Footer';
import { Headline } from '../components/Headline';
import PersonalData from '../components/personComponents/PersonalData';
import { ScrollToError } from '../components/ScrollToError';
import { emptyFunction } from '../helpers/emptyFunction';
import '../helpers/InlineTooltip.css';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { updatePerson } from '../services/api';
import { addTrackingData } from '../tracking/tracker';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import { createPersonalDataSchema } from '../validation/PersonalDataSchema';

export interface PolicyHolderPageData extends StoreStateUpdater<PolicyHolderPageData> {
    policyHolder: Person;
    birthdateField?: DateObject;
}

interface PolicyHolderPageProps extends PagePropsWithValues<PolicyHolderPageData> {
    businessId: string;
    showNavigateToOffer?: boolean;
}

const PolicyHolderPage = (props: PolicyHolderPageProps) => {
    const [intendedNavigationAction, setIntendedNavigationAction] = React.useState();
    const [isLoading, setIsLoading] = React.useState(false);
    const [spcsEmailError, setSpcsEmailError] = React.useState(false);
    const [spcsAddressError, setSpcsAddressError] = React.useState(false);

    React.useEffect(() => {
        scrollToTop();
    }, []);

    const onSubmit = async (values: any, {setErrors}: FormikActions<Person>) => {
        // Prevent the submitting of form in case of date error
        if (values.dateError !== undefined) {
            return;
        }
        const validatedValues = {
            ...props.storeState,
            ...createPersonalDataSchema(true).cast(values)
        } as PolicyHolderPageData;

        props.storeState.update(validatedValues);

        setIsLoading(true);
        const spcsResponse: any = await updatePerson(props.businessId, mapToUpdateRequest(values), props.storeState.policyHolder.personId!);
        const personResponse = spcsResponse.personResponse;
        // if exceptionDetails in spcsResponse === SPCS returns 422
        setSpcsEmailError('exceptionDetails' in personResponse);
        setSpcsAddressError(spcsResponse.spcsAddressValidationError);
        setIsLoading(false);

        addTrackingData({policyHolder: values});
        if (intendedNavigationAction && personResponse.data && !spcsResponse.spcsAddressValidationError)  {
            props.handleAction(intendedNavigationAction);
        }
    };

    return <>
        <Headline>
            Persönliche Daten des Versicherungsnehmers
        </Headline>
        <Formik
            initialValues={{
                ...props.storeState.policyHolder,
                update: props.storeState.update
            }}
            onSubmit={onSubmit}
            validationSchema={createPersonalDataSchema(true)}
        >
            {form => (
                <Form noValidate data-component-id={'policy-holder-form'}>
                    <LoadingSpinner show={isLoading}/>
                    <PersonalData
                        form={form}
                        inputData={props.storeState.policyHolder}
                        updateCallback={emptyFunction}
                        detailedMode={true}
                        disableBirthdate={false}
                        spcsEmailError={spcsEmailError}
                        spcsAddressError={spcsAddressError}
                    />
                    <ScrollToError formik={form}/>
                    <Footer
                        handleAction={props.handleAction}
                        showNavigateToOffer={props.showNavigateToOffer}
                        onNextClick={() => setIntendedNavigationAction(NavigationAction.NEXT)}
                        offerCallback={() => {
                            setIntendedNavigationAction(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);
                            form.submitForm();
                        }}
                    />
                </Form>
            )}
        </Formik>
    </>;
};

const mapToUpdateRequest = (person: Person): Partial<Person> => {
    return {
        anrede: person.anrede,
        vorname: person.vorname || '',
        nachname: person.nachname || '',
        birthdate: person.birthdate || '',
        adresse: {
            strasse: person.adresse ? person.adresse.strasse : '',
            hausnummer: person.adresse ? person.adresse.hausnummer : '',
            plz: person.adresse ? person.adresse.plz : '',
            ort: person.adresse ? person.adresse.ort : ''
        },
        email: person.email || undefined,
        vorwahl: person.vorwahl || undefined,
        rufnummer: person.rufnummer || undefined
    };
};

export default PolicyHolderPage;

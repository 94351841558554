import { NODE_ENV } from '../config';
import { TRACKING_VERSION } from '../releaseVersion';

export interface TrackjsConfig {
    token: string;
    application: string;
    version: string;
}

let trackJsConfig: TrackjsConfig | undefined;

switch (NODE_ENV) {
    case 'development':
    case 'test':
        trackJsConfig = undefined;
        break;
    default:
        trackJsConfig = {
            token: 'ac5193cbf5714332be98f2334a1552dd',
            application: 'kfo',
            version: TRACKING_VERSION
        };
        break;

}

export default trackJsConfig;

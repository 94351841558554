"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var EMailType;

(function (EMailType) {
  EMailType["PRIVAT"] = "privat";
  EMailType["GESCHAEFTLICH"] = "gesch\xE4ftlich";
})(EMailType = exports.EMailType || (exports.EMailType = {}));
// tslint:disable:no-magic-numbers no-invalid-this
import { differenceInYears } from 'date-fns';
import { IsoDateString, isValidIsoDateFormat, mapDateInputToIsoDateString } from 'kfo-common';
import { addMethod, mixed, NumberSchema } from 'yup';

export interface CustomMixedSchema extends NumberSchema {
    mapDateInputToIsoDateString(): this;
    isValidIsoDateFormat(message?: string): this;
    validateNumber(): this;
    required(message?: string): this;
}

export const DateErrorMessages = {
    FORMAT: 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ an.'
};

// When the field gets emptied yup struggles to validate it properly because the
// empty value translates to NaN, which can not be validated as a number.
// See: https://github.com/jquense/yup/issues/66
addMethod(mixed, 'validateNumber', function() {
    return this.transform(value => isNaN(value) ? 0 : value);
});

addMethod(mixed, 'mapDateInputToIsoDateString', function() {
    return this.transform(mapDateInputToIsoDateString);
});

addMethod(mixed, 'isValidIsoDateFormat', function(message?: string) {
    return this.test({
        name: 'isValidIsoDateFormat',
        exclusive: true,
        message: message || DateErrorMessages.FORMAT,
        test: isValidIsoDateFormat
    });
});

addMethod(mixed, 'isDateOlderThan', function(referenceDate: IsoDateString, message: string) {
    return this.test({
        name: 'isDateOlderThan',
        exclusive: true,
        message,
        test: (value: IsoDateString) =>
            isDateOlderThan(new Date(), value, 18)
    });
});

addMethod(mixed, 'isDateYoungerThan', function(referenceDate: IsoDateString, message: string) {
    return this.test({
        name: 'isDateYoungerThan',
        exclusive: true,
        message,
        test: (value: IsoDateString) =>
            isDateYoungerThan(new Date(), value, 75)
    });
});

export function isDateOlderThan(date: Date, birthday: IsoDateString, age: number): boolean {
    return evaluateBirthday(date, birthday, (deltaYears => deltaYears >= age));
}

export function isDateYoungerThan(date: Date, birthday: IsoDateString, age: number): boolean {
    return evaluateBirthday(date, birthday, (deltaYears => deltaYears < age));
}

export function isOlderThanInYear(date: Date, birthday: IsoDateString, age: number): boolean {
    return evaluateBirthyear(date, birthday, deltaYears => deltaYears >= age);
}

export function isYoungerThanInYear(date: Date, birthday: IsoDateString, age: number): boolean {
    return evaluateBirthyear(date, birthday, deltaYears => deltaYears < age);
}

function evaluateBirthyear(date: Date, birthday: IsoDateString, evaluationFunction: (deltaYears: number) => boolean): boolean {
    if (!birthday || !isValidIsoDateFormat(birthday)) {
        return false;
    }
    return evaluationFunction(date.getFullYear() - new Date(birthday).getFullYear());
}

function evaluateBirthday(date: Date, birthday: IsoDateString, evaluationFunction: (deltaYears: number) => boolean): boolean {
    if (!birthday || !isValidIsoDateFormat(birthday)) {
        return false;
    }

    return evaluationFunction(differenceInYears(date, new Date(birthday)));
}

import { getConfigVariable } from '../helpers/configurableVariables';
import { getQueryValue } from '../helpers/QueryParameterExtractor';
import { TRACKING_VERSION } from '../releaseVersion';
import { StateName } from '../routing/StateMachineTypes';
import { AemConfigKey } from '../types/AemConfig';

export const mapPageData = (statename?: StateName) => {
    return {
        page: {
            attributes: {displayedBrand: 'ERGO'},
            pageInfo: {
                sysEnv: getSysEnv(),
                issueDate: getBuildTime(),
                pageName: getTrackingPageName(statename)
            },
            category: {
                pagePurpose: 'Contract',
                primaryCategory: 'kfo2020'
            }
        }
    };
};

const getBuildTime = () => {
    return `OTRkfo2020|${TRACKING_VERSION}`;
};

const getSysEnv = () => {
    return getQueryValue('adh_internet') || window.location.host;
};

export const getTrackingPageName = (statename?: StateName) => {
    const pageName = mapPageName(statename);
    const trackingKuerzel = getConfigVariable('trackingKuerzel') as string;
    const trackingVariableName = `trackingName${pageName}` as AemConfigKey;
    const trackingName = getConfigVariable(trackingVariableName);

    if (trackingKuerzel && trackingName && statename) {
        return `${trackingKuerzel}:${trackingName}`;
    } else {
        return `kfo:${pageName}`;
    }
};

const mapPageName = (statename?: StateName) => {
    switch (statename) {

        case StateName.BIRTHDATE_PAGE:
            return 'Basisdaten';
        case StateName.INSURANCE_BEGIN_PAGE:
            return 'BasisdatenVersBeginn';
        case StateName.FEE_PAGE:
            return 'Berechnungsergebnis';
        case StateName.POLICY_HOLDER_PAGE :
            return 'Personendaten';
        case StateName.INSURED_PERSONS_PAGE :
            return 'PersonendatenVP';
        case StateName.BANKING_DETAILS_PAGE :
            return 'PersonendatenBankdaten';
        case StateName.INSURANCE_SUMMARY_PAGE :
            return 'PruefenUndSenden';
        case StateName.INSURANCE_DOCUMENTS_PAGE :
            return 'PruefenUndSendenIDD';
        case StateName.FEED_BACK_PAGE :
            return 'AbschlussFeedback';
        case StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE:
            return 'PersonendatenAngebot';
        case StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE:
            return 'PersonendatenVPAngebot';
        case StateName.REQUEST_OFFER_CHECKOUT_PAGE:
            return 'PruefenUndSendenAngebot';
        case StateName.REQUEST_OFFER_FEED_BACK_PAGE:
            return 'AngebotFeedback';
        default:
            return undefined;
    }
};

import { THEME } from '@eg/elements/Provider';
import { AemConfig, AemConfigKey, AemConfigValue } from '../types/AemConfig';

const DEFAULT_AEM_VALUES: AemConfig = {
  OENummer: '001280200',
  betreuungsweicheOn: 'false',
  trackingNamePersonendaten: 'Personendaten',
  'sling:resourceType': 'ergo/carbon/base/components/appcatalog/applicationembed',
  envs: [
    {
      name: 'url',
      url: window.location.origin
    }
  ],
  trackingNamePersonendatenBankdaten: 'PersonendatenBankdaten',
  trackingNamePruefenUndSenden: 'PruefenUndSenden',
  quellsystem: 'ERGO_INTERNET',
  trackingNameAbschlussFeedback: 'Abschluss Feedback',
  trackingNameBasisdatenVersBeginn: 'BasisdatenVersBeginn',
  trackingNameBerechnungsergebnis: 'Berechnungsergebnis',
  theme: THEME.ergoone,
  id: 'RHhlY',
  'cq:styleIds': ['applicationembed--full-width'],
  pageProperties: {},
  trackingNamePersonendatenVP: 'PersonendatenVP',
  trackingNamePruefenUndSendenIDD: 'PruefenUndSendenIDD',
  trackingNamePersonendatenVPAngebot: 'PersonendatenVPAngebot',
  zahntarifErwachseneUrl: '/content/ergo/ergo-one/ergo-one-portal/de/Produkte/Zahnzusatzversicherung',
  trackingKuerzel: 'kfo',
  enableVollangebot: 'true',
  trackingNamePersonendatenAngebot: 'PersonendatenAngebot',
  trackingNamePruefenUndSendenAngebot: 'PruefenUndSendenAngebot',
  trackingNameAngebotFeedback: 'Angebot Feedback',
  trackingNameBasisdaten: 'Basisdaten',
  trackingProduktgruppe: 'ZahnZusatz'
};

export const getConfigVariable = (variable: AemConfigKey): AemConfigValue => {
  const appId = (document.querySelector('[id^=\'kfo-otr\']') || {id: ''}).id;
  const params = window.params || {};
  // TODO - change this line to the following one after moving fully to app catalogue
  // const configVariables = params[appId] || DEFAULT_AEM_VALUES;
  const configVariables = window.aemassets || window.gwtassets || params[appId] || DEFAULT_AEM_VALUES;

  return configVariables[variable] as AemConfigValue;
};

export const isVollangebotEnabled = (): boolean => {
    return getConfigVariable('enableVollangebot') === 'true';
};

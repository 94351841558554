import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import { Key } from './Key';

// tslint:disable-next-line:no-any
export const getErrorMessage = (form: FormikProps<any>, field: { name: string }): string | undefined => {
    const errorMessage = getFormErrorORTouched(form.errors, field);
    const touched = getFormErrorORTouched(form.touched, field);
    if (errorMessage && (touched || form.isSubmitting)) {
        return errorMessage;
    }
    return undefined;
};

export const filterNumberInputField = (event: React.KeyboardEvent) => {
    if (event.which === Key.E || event.which === Key.ALPHA_PLUS || event.which === Key.NUMPAD_PLUS
        || event.which === Key.COMMA || event.which === Key.NUMPAD_COMMA || event.which === Key.PERIOD
        || event.which === Key.NUMPAD_MINUS || event.which === Key.ALPHA_MINUS) {
        event.preventDefault();
    }
};

export const limitMaxLength = (value: string, maxLength: number): string => value.substr(0, maxLength);

// tslint:disable-next-line:no-any
export const getFormErrorORTouched = (formErrors: FormikErrors<any> | FormikTouched<{}>, field: { name: string }) => {
    let helperObject;
    let messageOrTouchedResult;
    let names = [field.name];
    if (field.name.includes('.')) {
        names = field.name.split('.');
    }
    for (const name of names) {
        const formErrorElement = formErrors[name];
        if (formErrorElement && typeof (formErrorElement) === 'object') {
            helperObject = formErrors[name];
        } else if (helperObject && helperObject[name] && typeof (helperObject[name]) === 'object') {
            helperObject = helperObject[name];
        } else if (formErrors[name] && (typeof (formErrors[name]) === 'boolean' || typeof (formErrors[name]) === 'string')) {
            messageOrTouchedResult = formErrors[name];
        } else if (helperObject && helperObject[name] && (typeof (helperObject[name]) === 'boolean' || typeof (helperObject[name]) === 'string')) {
            messageOrTouchedResult = helperObject[name];
        }
    }
    return messageOrTouchedResult;
};

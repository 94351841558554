interface TxtInterface {
  hint: {
    [key: string]: string | string[];
  };
  hintModal: {
    [key: string]: string | string[];
  };
}

const txt: TxtInterface = {
  hint: {
    mainTitle: `Sie können nur Kinder versichern, wenn es sich um nahe Angehörige handelt, da andernfalls Versicherungssteuerpflicht eintreten kann.`,
    modalOpenAction: ` (nahen) Angehörige `,
    checkBoxValueFirst: 'Ich bestätige, dass es sich bei den Kindern um ',
    checkBoxValueSecond: 'handelt. Wer dazu zählt, ist gesetzlich geregelt <strong>in § 7 des Pflegezeitgesetzes und § 15 der Abgabenordnung.</strong>',
    last: 'Wenn Sie Fragen haben, rufen Sie einfach an.'
  },
  hintModal: {
    mainTitle: 'Nahe Angehörige und damit versicherbar sind:',
    menuPoints: [
      'Kinder, Adoptiv- oder Pflegekinder',
      'Kinder, Adoptiv- oder Pflegekinder des Ehegatten oder Lebenspartners',
      'Kinder der Geschwister',
      'Enkelkinder und Urenkelkinder'
    ],
    firstEntry:
      'Achtung: Die Beendigung der häuslichen Gemeinschaft mit Pflegekindern nach Vertragsschluss kann dazu führen, dass Versicherungssteuerpflicht eintritt. In diesem Fall müssen Sie uns umgehend informieren.',
    secondEntry:
      'Sie könnten dann möglicherweise zur Zahlung der Versicherungssteuer verpflichtet sein – zusätzlich zu Ihrem Beitrag.'
  }
};

export default txt;

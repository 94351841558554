import { OfferType } from 'kfo-common';
import * as React from 'react';
import { InsuranceSummaryPageData } from '../../../pages/InsuranceSummaryPage';
import RequestOfferCheckoutPage from '../../../pages/RequestOfferPages/RequestOfferCheckoutPage';
import { getOfferPageData, makeOffer } from '../../../services/api';
import { createOfferNavigation } from '../../routeHelper';
import { StateDefinition } from '../../StateMachine';
import { NavigationAction, StateName } from '../../StateMachineTypes';

export const requestOfferCheckoutPage: StateDefinition<InsuranceSummaryPageData> = {
    name: StateName.REQUEST_OFFER_CHECKOUT_PAGE,
    transitions: [
        // Navigation
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_DIRECT_ONLINE,
            newState: StateName.REQUEST_OFFER_FEED_BACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_POSTAL,
            newState: StateName.REQUEST_OFFER_FEED_BACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.OFFER_WRITTEN_EMAIL,
            newState: StateName.REQUEST_OFFER_FEED_BACK_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            resolveNewState: createOfferNavigation(StateName.REQUEST_OFFER_POLICY_HOLDER_PAGE,
                [StateName.INSURED_PERSONS_PAGE, StateName.BANKING_DETAILS_PAGE, StateName.INSURANCE_SUMMARY_PAGE]),
            newState: StateName.REQUEST_OFFER_INSURED_PERSONS_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.POLICY_HOLDER_PAGE
        },

        // Direct Jumps
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_POLICY_HOLDER_PAGE,
            newState: StateName.POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_INSURED_PERSONS_PAGE,
            newState: StateName.INSURED_PERSONS_PAGE
        }

    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceSummary');

        return {
            userInput: {
                insuranceBegin: response.insuranceBegin,
                insuranceFeeTotal: response.insuranceFeeTotal,
                paymentMethod: response.paymentMethod,
                policyHolder: response.policyHolder,
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        if (transitionDetails.targetStateName === StateName.REQUEST_OFFER_FEED_BACK_PAGE) {
            let offerType;
            switch (transitionDetails.action) {
                case NavigationAction.OFFER_WRITTEN_POSTAL:
                    offerType = OfferType.WRITTEN_POSTAL;
                    break;
                case NavigationAction.OFFER_WRITTEN_EMAIL:
                    offerType = OfferType.WRITTEN_EMAIL;
                    break;
                default:
                    offerType = OfferType.DIRECT_ONLINE;
                    break;
            }
            await makeOffer(inputData.businessId, offerType);
        }
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <RequestOfferCheckoutPage
            storeState={{
                businessId: inputData.businessId,
                insuranceBegin: inputData.userInput.insuranceBegin,
                insuranceFeeTotal: inputData.userInput.insuranceFeeTotal,
                paymentMethod: inputData.userInput.paymentMethod,
                policyHolder: inputData.userInput.policyHolder,
                insuredPersons: inputData.userInput.insuredPersons,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

import { isAfter } from 'date-fns';
import { IsoDateString, isValidIsoDateFormat, parseDate, ValueRanges } from 'kfo-common';
import { array, mixed, object, Schema } from 'yup';
import { CustomMixedSchema, isDateOlderThan, isDateYoungerThan } from './date';

const getErrorMessage = (value: IsoDateString, valueRanges: ValueRanges) => {
    const insuranceBegin = valueRanges.insuranceStart.possibleValues;
    insuranceBegin.sort();
    const earliestInsuranceBegin = insuranceBegin[0];

    if (!value || (value.length === 0 || value === '-00-00')) {
        return 'Bitte geben Sie das Geburtsdatum der zu versichernden Person an.';
    }
    if (!isValidIsoDateFormat(value)) {
        return 'Bitte geben Sie ein gültiges Datum im Format TT.MM.JJJJ an.';
    }
    if (isDateOlderThan(parseDate(earliestInsuranceBegin) || new Date(), value, valueRanges.ageAtEntry.max + 1)) {
        return `Der Versicherte darf höchstens ${valueRanges.ageAtEntry.max} Jahre alt sein.`;
    }
    if (isDateYoungerThan(new Date(), value, valueRanges.ageAtEntry.min)) {
        return `Die versicherte Person muss mindestens ${valueRanges.ageAtEntry.min} Jahr alt sein.`;
    }
    if (isAfter(parseDate(value) || new Date(), new Date())) {
        return `Die versicherte Person muss geboren sein.`;
    }
    return '';
};

export function createBirthdatePageSchema(valueRanges: ValueRanges): Schema<{}> {
    return object().shape({
        insuredPersons: array().of(
            object().shape({
                birthdate:
                    (mixed() as CustomMixedSchema)
                        .test({
                            message: ({value}) => {
                                return getErrorMessage(value, valueRanges);
                            },
                            test: (value: string) => {
                                const errorMessage = getErrorMessage(value, valueRanges);
                                return errorMessage.length === 0;
                            }
                        })
            })
        )
    });
}

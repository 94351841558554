import Button from '@eg/elements/Button';
import { DownloadIcon, EmailIcon, MailIcon } from '@eg/elements/components/Icons';
import InputRow from '@eg/elements/InputRow';
import Modal from '@eg/elements/Modal';
import { Person } from 'kfo-common';
import * as React from 'react';
import { CheckoutInsuredPersons } from '../../components/checkout/CheckoutInsuredPersons';
import { CheckoutPolicyHolder } from '../../components/checkout/CheckoutPolicyHolder';
import { CheckoutSeperator } from '../../components/checkout/CheckoutSperator';
import { CheckoutTariffDetails } from '../../components/checkout/CheckoutTariffDetails';
import Footer, { NextButtonTyp } from '../../components/Footer';
import { Headline } from '../../components/Headline';
import { mapAddressesToPrintableText } from '../../helpers/addressesHelper';
import { scrollToTop } from '../../helpers/scrolling';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { putPerson } from '../../services/api';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';
import { PagePropsWithValues } from '../../types/PageProps';
import { emailPattern } from '../../validation/PersonalDataSchema';
import { InsuranceSummaryPageData } from '../InsuranceSummaryPage';

export interface RequestOfferCheckoutPageData extends InsuranceSummaryPageData {
    businessId: string;
}

const RequestOfferCheckoutPage = (props: PagePropsWithValues<RequestOfferCheckoutPageData>) => {

    const [requiresEmail] = React.useState(!props.storeState.policyHolder.email);
    const [isShowEMailModal, setShowEMailModal] = React.useState(false);
    const [isEMailError, setEMailError] = React.useState(false);
    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        scrollToTop();
    }, []);

    const renderOfferButtons = () => {
        return <div className="personal-data-page" data-component-id="RequestOfferCheckoutPage"
                    style={{maxWidth: '400px', textAlign: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
            <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                    data-component-id="request-offer-checkout-button-download"
                    iconLeft={DownloadIcon}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Button_AngebotPDFHerunterladen);
                        props.handleAction(NavigationAction.OFFER_DIRECT_ONLINE);
                    }}
                    size="large">
                Sofort Download
            </Button>
            <br/>
            <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                    data-component-id="request-offer-checkout-button-email"
                    iconLeft={EmailIcon}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Button_AngebotPerEmail);
                        if (requiresEmail) {
                            setShowEMailModal(true);
                        } else {
                            props.handleAction(NavigationAction.OFFER_WRITTEN_EMAIL);
                        }
                    }}
                    size="large">
                Angebot per E-Mail
            </Button>
            <br/>
            <Button variant="primary" style={{marginTop: '3em', width: '220px'}}
                    data-component-id="request-offer-checkout-button-mail"
                    iconLeft={MailIcon}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Button_AngebotPerPost);
                        props.handleAction(NavigationAction.OFFER_WRITTEN_POSTAL);
                    }}
                    size="large">
                Angebot per Post
            </Button>
            <br/>
            <Button variant="text-link" style={{marginTop: '2em', width: '220px', fontWeight: 'bold'}}
                    data-component-id="direct-jump-banking-details-page"
                    onClick={() => {
                        trackElementClicked(TrackingElement.Button_WeiterMitAbschluss);
                        props.handleAction(NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE);
                    }}
                    size="large">
                Weiter mit Abschluss
            </Button>
        </div>;
    };

    function renderEMailModal() {
        const maxLengthEmail = 51;
        const onClickHandler = () => {
            if (isEmailValid(email)) {
                // tslint:disable-next-line:no-floating-promises
                putPerson(props.storeState.businessId, {email}, props.storeState.policyHolder.personId!)
                    .then(() => {
                        props.handleAction(NavigationAction.OFFER_WRITTEN_EMAIL);
                    });
            } else {
                setEMailError(true);
            }
        };
        return <Modal open={isShowEMailModal}>
            <p style={{marginBottom: '3em'}}>
                Damit wir das Angebot per E&#8209;Mail zuschicken können, brauchen wir die E&#8209;Mail&#8209;Adresse des Versicherungsnehmer.
            </p>
            <InputRow
                label="E-Mail-Adresse:" id="email" name="email" maxLength={maxLengthEmail}
                data-component-id="request-offer-checkout-email"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmail(event.target.value);
                }}
                error={isEMailError ? 'Bitte geben Sie eine gültige E-Mail Adresse ein.' : ''}
            />
            <br/><br/>
            <Button variant="tertiary" onClick={() => {
                setShowEMailModal(false);
                setEMailError(false);
            }}>Abbrechen</Button>
            <Button variant="primary" onClick={onClickHandler} style={{float: 'right'}}>Antrag anfordern</Button>
        </Modal>;
    }

    return <>
        {renderPageHeading(props.storeState.policyHolder)}
        <CheckoutSeperator/>

        <CheckoutTariffDetails insuredPersons={props.storeState.insuredPersons}
                               paymentMethod={props.storeState.paymentMethod}
                               insuranceTotalFee={props.storeState.insuranceFeeTotal}
                               handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <CheckoutPolicyHolder policyHolder={props.storeState.policyHolder}
                              handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        <CheckoutInsuredPersons insuredPersons={props.storeState.insuredPersons}
                                handleAction={props.handleAction}/>
        <CheckoutSeperator/>

        {renderOfferButtons()}

        <Footer
            nextButtonType={NextButtonTyp.DISABLED}
            handleAction={props.handleAction}
        />

        {renderEMailModal()}
    </>;
};

const isEmailValid = (email: string) => {
    return email && emailPattern.test(email);
};

const renderPageHeading = (policyHolder: Person): JSX.Element => {

    return (<Headline>
            {mapAddressesToPrintableText(policyHolder.anrede) || policyHolder.vorname} {policyHolder.nachname},
            bitte prüfen Sie, ob Ihre Angaben richtig und vollständig sind.
        </Headline>
    );
};

export default RequestOfferCheckoutPage;

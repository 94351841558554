import * as React from 'react';
import InsuranceSummaryPage, { InsuranceSummaryPageData } from '../../pages/InsuranceSummaryPage';
import { getOfferPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuranceSummaryPage: StateDefinition<InsuranceSummaryPageData> = {
    name: StateName.INSURANCE_SUMMARY_PAGE,
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.INSURANCE_DOCUMENTS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.POLICY_HOLDER_PAGE
        },

        // Direct Jumps
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_FEE_PAGE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_BANKING_DETAILS_PAGE,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_POLICY_HOLDER_PAGE,
            newState: StateName.POLICY_HOLDER_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_INSURED_PERSONS_PAGE,
            newState: StateName.INSURED_PERSONS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        }

    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceSummary');

        return {
            userInput: {
                iban: response.iban,
                insuranceBegin: response.insuranceBegin,
                insuranceFeeTotal: response.insuranceFeeTotal,
                paymentMethod: response.paymentMethod,
                policyHolder: response.policyHolder,
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuranceSummaryPage
            storeState={{
                iban: inputData.userInput.iban,
                insuranceFeeTotal: inputData.userInput.insuranceFeeTotal,
                policyHolder: inputData.userInput.policyHolder,
                paymentMethod: inputData.userInput.paymentMethod,
                insuredPersons: inputData.userInput.insuredPersons,
                insuranceBegin: inputData.userInput.insuranceBegin,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
        />;
    }
};

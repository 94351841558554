import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { EditIcon } from '@eg/elements/components/Icons';
import { mapToGermanDate, Person } from 'kfo-common';
import * as React from 'react';
import { mapAddressesToPrintableText } from '../../helpers/addressesHelper';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface CheckoutPolicyHolderProps {
    policyHolder: Person;
    handleAction: HandleActionCallback;
}

export const CheckoutPolicyHolder = (props: CheckoutPolicyHolderProps) => {
    const policyHolder = props.policyHolder;
    if (!policyHolder) {
        return <></>;
    }

    return (<div style={{textAlign: 'center'}}>
        <h3 style={{margin: '0 0 3em 0'}} data-component-id="checkout-policy-holder-header">Persönliche Daten</h3>
        {policyHolder &&
        <Card padding="normal" data-component-id="checkout-policy-holder-data">
            <div className="esc_grid">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-12" style={{textAlign: 'left'}}>
                        <div style={{fontSize: '18px', borderBottom: '1px solid #D9D9D9'}}>
                            Versicherungsnehmer
                        </div>
                        <div style={{borderTopWidth: '10px', borderBottomWidth: '0px', margin: '1em 0'}}>
                            <b data-component-id="policy-holder-name">{mapAddressesToPrintableText(policyHolder.anrede)}&nbsp;
                                {policyHolder.vorname}&nbsp;
                                {policyHolder.nachname}</b>
                            <br/>
                            <small data-component-id="policy-holder-birthdate">{mapToGermanDate(policyHolder.birthdate)}</small>
                        </div>
                        {policyHolder.adresse && policyHolder.adresse.strasse &&
                        <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            <small>
                                <b>Straße: </b>
                                <span data-component-id="policy-holder-address-street">{policyHolder.adresse.strasse} {policyHolder.adresse.hausnummer}</span>
                            </small>
                        </div>}
                        {policyHolder.adresse && policyHolder.adresse.ort &&
                        <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            <small>
                                <b>Ort: </b>
                                <span data-component-id="policy-holder-address-city">{policyHolder.adresse.plz}, {policyHolder.adresse.ort}</span>
                            </small>
                        </div>}
                        {policyHolder.email && <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            <small>
                                <b>E-Mail: </b>
                                <span data-component-id="policy-holder-email">{policyHolder.email}</span>
                            </small>
                        </div>}
                        {policyHolder.vorwahl && <div style={{borderTopWidth: '0px', borderBottomWidth: '0px', lineHeight: '20px'}}>
                            <small>
                                <b>Telefonnummer: </b>
                                <span data-component-id="policy-holder-phone">{`${policyHolder.vorwahl} / ${policyHolder.rufnummer}`}</span>
                            </small>
                        </div>}
                    </div>
                </div>
            </div>
        </Card>
        }
        <Button width="auto" variant="text-link" size="large" style={{fontWeight: 'bold'}} iconLeft={EditIcon}
                onClick={() => {
                    trackElementClicked(TrackingElement.Link_VersicherungsnehmerAendern);
                    props.handleAction(NavigationAction.DIRECT_JUMP_POLICY_HOLDER_PAGE);
                }}
                data-component-id="checkout-policy-holder-edit-button">
            ändern
        </Button>
    </div>);
};

// tslint:disable-next-line:no-any
import { StateDefinition } from '../StateMachine';
import { bankingDetailsPage } from './bankingDetailsPage';
import { birthdatePage } from './birthdatePage';
import { feedBackPage } from './feedBackPage';
import { feePage } from './feePage';
import { insuranceBeginPage } from './insuranceBeginPage';
import { insuranceDocumentsPage } from './insuranceDocumentsPage';
import { insuranceSummaryPage } from './insuranceSummaryPage';
import { insuredPersonsPage } from './insuredPersonsPage';
import { policyHolderPage } from './policyHolderPage';
import { requestOfferCheckoutPage } from './RequestOfferRoutes/requestOfferCheckoutPage';
import { requestofferFeedBackPage } from './RequestOfferRoutes/requestOfferFeedBackPage';
import { requestOfferInsuredPersonsPage } from './RequestOfferRoutes/requestOfferInsuredPersonsPage';
import { requestOfferPolicyHolderPage } from './RequestOfferRoutes/requestOfferPolicyHolderPage';

// tslint:disable-next-line:no-any
export const stateMachineStates: Array<StateDefinition<any>> = [
    birthdatePage,
    insuranceBeginPage,
    feePage,
    policyHolderPage,
    insuredPersonsPage,
    bankingDetailsPage,
    insuranceSummaryPage,
    insuranceDocumentsPage,
    feedBackPage,
    requestOfferPolicyHolderPage,
    requestOfferInsuredPersonsPage,
    requestOfferCheckoutPage,
    requestofferFeedBackPage
];

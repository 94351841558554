import Card, { CardProps } from '@eg/elements/Card';
import { Checkbox } from '@eg/elements/Checkbox';
import Modal from '@eg/elements/Modal';
import * as React from 'react';
import './InsuranceTaxHint.css';
import txt from './InsuranceTaxHintText';

export interface InsuranceTaxHintProps {
  handleInsuranceTaxHint: () => void;
  isChecked: boolean;
}

export interface InsuranceTaxHintState {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}

const hintConfiguration: CardProps = {
  padding: 'auto',
  label: 'Hinweis',
  chipColor: '#E87A16',
  accentColor: '#E87A16',
  labelColor: '#fff',
  alignLabel: 'left',
  style: {
    boxShadow: 'none !important',
    paddingBottom: '0px !important',
    textAlign: 'left'
  }
};

const InsuranceTaxHint = (props: InsuranceTaxHintProps) => {
  const { isChecked, handleInsuranceTaxHint } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const modalOpen = () => setIsModalOpen(true);
  const modalClose = () => setIsModalOpen(false);

  return (
    <>
      <Card {...hintConfiguration}>
        <h3>{txt.hint.mainTitle}</h3>
        <div className="taxhint__check-wrapper">
          <div>
            <Checkbox
              // why label empty? Part of text needs to be clickable (not the whole) for popup,
              // so did in a different way
              label=""
              onChange={handleInsuranceTaxHint}
              checked={isChecked}
            />
          </div>
          <div>
            {txt.hint.checkBoxValueFirst}
            <span onClick={modalOpen} className="taxhint__link" id="taxhintModalOpen">
              {txt.hint.modalOpenAction}
            </span>
            <span dangerouslySetInnerHTML={{ __html: txt.hint.checkBoxValueSecond.toString() }} />
            <p>{txt.hint.last}</p>
          </div>
        </div>
      </Card>
      <Modal
        extendedWidth
        open={isModalOpen}
        onDismiss={modalClose}
        dismissible
      >
        <h3>{txt.hintModal.mainTitle}</h3>
        <ul className="taxhint__list">
          {(txt.hintModal.menuPoints as string[]).map((entry, index) => (
            <li key={entry} className="taxhint__bullet">
              <span className="taxhint__bullet-number">
                {index + 1}
              </span>
              {entry}
            </li>
          ))}
        </ul>
        <p>{txt.hintModal.firstEntry}</p>
        <p>{txt.hintModal.secondEntry}</p>
      </Modal>
    </>
  );
};

export default InsuranceTaxHint;

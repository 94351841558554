import Button from '@eg/elements/Button';
import { OrdersIcon } from '@eg/elements/components/Icons';
import { ConsultingInfo } from '@eg/idd-components';
import * as React from 'react';
import { isVollangebotEnabled } from '../helpers/configurableVariables';
import { HandleActionCallback } from '../routing/StateMachine';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked, trackElementClickImmediate } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import './Footer.css';

export interface FooterProps {
    handleAction: HandleActionCallback;
    onNextClick?: () => void;
    nextButtonType?: NextButtonTyp;
    nextButtonText?: string;
    disableNextButton?: boolean;
    showLoadingSpinner?: boolean;
    showConsultingInfo?: boolean;
    showNavigateToOffer?: boolean;
    offerCallback?: () => void;
}

export enum NextButtonTyp {
    DISABLED
}

const Footer = (props: FooterProps) => {
    return (<div className="footer">
            {renderNextButton(props)}
            {props.showConsultingInfo && renderConsultingInfo()}
            {isVollangebotEnabled() && props.showNavigateToOffer && renderRequestOffer(props)}
            {renderBackButton(props)}
        </div>
    );
};

const renderBackButton = (props: FooterProps): JSX.Element => {

    return <div className="esc_text-link back-button"
                data-component-id={'back-button'}
                onClick={() => {
                    trackElementClicked(TrackingElement.Button_Zurueck);
                    props.handleAction(NavigationAction.BACK);
                }}>
        Zurück
    </div>;
};

const renderConsultingInfo = (): JSX.Element => {
    return <div className="esc_text-link footer-consultingInfo">
        <ConsultingInfo
            data-component-id="consulting-info-button"
            showTelephonenumber={false}
            viewport={'relative'}
            onOpenModal={() => trackElementClickImmediate(TrackingElement.Link_IDDBeratung)}/>
    </div>;
};

const renderRequestOffer = (props: FooterProps): JSX.Element => {
    return <Button
        type="button"
        data-component-id="button_angebot"
        variant="text-link"
        className="footer-requestOffer-button"
        iconLeft={OrdersIcon}
        onClick={() => {
            trackElementClicked(TrackingElement.Link_AngebotAnfordern);
            if (props.offerCallback) {
                props.offerCallback();
            } else {
                props.handleAction(NavigationAction.DIRECT_JUMP_REQUEST_OFFER);
            }
        }}>
        Angebot anfordern
    </Button>;
};

const renderNextButton = (props: FooterProps) => {
    const onClick = () => {
        trackElementClicked(TrackingElement.Button_Weiter);
        if (props.onNextClick) {
            props.onNextClick();
        }
    };

    switch (props.nextButtonType) {
        case NextButtonTyp.DISABLED:
            return (<></>);
        default:
            return (<Button
                type="submit"
                variant="primary"
                size="large"
                disabled={props.disableNextButton}
                onClick={onClick}
                data-component-id={'next-button'}
                className="footer-next-button"
                loading={props.showLoadingSpinner}
            >{props.nextButtonText || 'Weiter'}</Button>);
    }
};

export default Footer;

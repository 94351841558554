import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { AlertIcon, SaveCheckIcon } from '@eg/elements/components/Icons';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { Adresse, ValueRanges } from 'kfo-common';
import * as React from 'react';
import { InsuredPersonViewModel } from '../../pages/InsuredPersonsPage';
import { trackElementClickImmediate } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';
import { createPersonalDataSchema } from '../../validation/PersonalDataSchema';
import PersonalData from './PersonalData';

export interface EditViewProps {
    headline: string;
    insuredPerson: InsuredPersonViewModel;
    policyHolder?: Adresse;
    updateCallback: (insuredPerson: Partial<InsuredPersonViewModel>) => void;
    saveCallback: (insuredPerson: InsuredPersonViewModel) => Promise<void>;
    valueRanges: ValueRanges;
    registerViewFormCallback: (pdsId: string, formikForm: FormikProps<FormikValues>) => void;
    submitFinishedCallback: () => void;
    showUsePolicyHolderData: boolean;
}

export interface EditViewState {
    saveLoading: boolean;
    errorMessage?: string;
}

class EditView extends React.Component<EditViewProps, EditViewState> {

    constructor(props: EditViewProps) {
        super(props);
        this.state = {
            saveLoading: false
        };
    }

    public render() {
        return (
            <Formik
                initialValues={this.props.insuredPerson}
                validationSchema={createPersonalDataSchema(false, false)}
                onSubmit={async values => {
                    this.setState({saveLoading: true});
                    try {
                        await this.props.saveCallback(values);
                        this.props.submitFinishedCallback();
                        this.setState({
                            saveLoading: false,
                            errorMessage: 'Address is not correct'
                        });
                    } catch (e) {
                        this.props.submitFinishedCallback();
                        this.setState({
                            saveLoading: false,
                            errorMessage: 'Error saving person'
                        });
                    }
                }}
            >
                {(formikForm: FormikProps<InsuredPersonViewModel>) => {
                    this.props.registerViewFormCallback(this.props.insuredPerson.personId!, formikForm);
                    return (
                        <Card alignLabel="left">
                            <Form data-component-id="edit-view-form" noValidate>
                                <div>
                                    <h3>{this.props.headline}</h3>
                                    <br/>

                                    <PersonalData
                                        form={formikForm}
                                        inputData={this.props.insuredPerson}
                                        updateCallback={this.props.updateCallback}
                                        detailedMode={false}
                                        disableBirthdate={true}
                                        disableInfoBoxEudsgvo={true}
                                        showUsePolicyHolderData={true}
                                        policyHolderAddress={this.props.policyHolder}
                                    />

                                </div>
                                {this.state.errorMessage && <div style={{color: '#8e0038', fontSize: '13px'}}>
                                    <AlertIcon
                                        // tslint:disable-next-line: no-magic-numbers
                                        width={16} height={16}/> {this.state.errorMessage}
                                </div>}
                                <div className="esc_grid">
                                    <div className="esc_grid__wrapper">
                                        <div className="esc_col esc_col" style={{textAlign: 'right', flex: 'auto'}}>
                                            <Button variant="primary" loading={this.state.saveLoading}
                                                    data-component-id="edit-view-save"
                                                    type="submit"
                                                    iconLeft={SaveCheckIcon}
                                                    onClick={() => {
                                                        trackElementClickImmediate(TrackingElement.Button_Save_Child);
                                                        formikForm.submitForm();
                                                    }}
                                                    size="large"
                                            >Person speichern</Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Card>
                    );
                }}
            </Formik>
        );
    }
}

export default EditView;

import Checkbox from '@eg/elements/Checkbox';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import StepButton from '@eg/elements/StepButton';
import * as React from 'react';
import Footer, { NextButtonTyp } from '../components/Footer';
import { CONFIG_BACKEND_BASE_URL } from '../config';
import { scrollToTop } from '../helpers/scrolling';
import { NavigationAction } from '../routing/StateMachineTypes';
import { trackElementClicked } from '../tracking/tracker';
import { TrackingElement } from '../tracking/trackingConstants';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';
import './InsuranceDocumentsPage.css';

export interface InsuranceDocumentsPageData
  extends StoreStateUpdater<InsuranceDocumentsPageData> {
  businessId: string;
}

interface InsuranceDocumentsPageProps
  extends PagePropsWithValues<InsuranceDocumentsPageData> {
  onError: (e: Error) => void;
}

interface InsuranceDocumentsPageState {
  isOrdering: boolean;
  isDocumentDownloaded: boolean;
  isFirstCheckboxClick: boolean;
  isCheckboxClicked: boolean;
  checkboxError: boolean;
}

class InsuranceDocumentsPage extends React.Component<
  InsuranceDocumentsPageProps,
  InsuranceDocumentsPageState
> {
  constructor(props: Readonly<InsuranceDocumentsPageProps>) {
    super(props);
    this.state = {
      isOrdering: false,
      isDocumentDownloaded: false,
      isFirstCheckboxClick: true,
      isCheckboxClicked: false,
      checkboxError: false
    };
  }

  public async componentDidMount() {
    scrollToTop();
  }

  public async componentDidUpdate(
    prevProps: InsuranceDocumentsPageProps,
    prevState: InsuranceDocumentsPageState
  ) {
    if (prevState.isCheckboxClicked !== this.state.isCheckboxClicked) {
      this.setState({
        ...this.state,
        checkboxError: !this.state.isCheckboxClicked
      });
    }
  }

  public render() {
    const orderButtonStepNumber = 2;
    const {
      isDocumentDownloaded,
      isFirstCheckboxClick,
      checkboxError,
      isOrdering
    } = this.state;

    return (
      <>
        <LoadingSpinner show={isOrdering} />
        <h2 data-component-id="insurance-documents-page-header">
          Bitte laden Sie zuerst alle Vertragsunterlagen herunter und schließen
          Sie den Vertrag dann ab.
        </h2>
        <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          Mit Ihrem Klick auf 'Vertrag Online abschließen' werden Ihre Daten
          verbindlich an uns übertragen. Innerhalb weniger Tage erhalten Sie
          dann Ihre Vertragsunterlagen per Post.
          <br />
          <br />
          <div>
            <h2>EU-Vorgabe: Beratungspflicht</h2>
            <p>
              Seit Februar 2018 gilt auch für Vertragsabschlüsse per Internet:
              Wir müssen Sie vorher beraten und dies dokumentieren. Sie können
              auch schnell und dafür ohne Beratung abschließen. Dann brauchen
              wir vor dem Abschluss Ihren Beratungsverzicht.
            </p>
            <Checkbox
              label={
                'Ich verzichte für diesen Vertragsabschluss auf eine persönliche Beratung sowie deren Dokumentation. Dies kann sich evtl. auf die Geltendmachung von Schadensersatzansprüchen nachteilig auswirken.'
              }
              onChange={this.validateCheckBox}
            />
            {!isFirstCheckboxClick && checkboxError && (
              <div className="ee_message-box ee_message-box--type-error">
                Bitte bestätigen Sie, dass Sie für diesen Abschluss keine
                Beratung benötigen.
              </div>
            )}
            <hr className="eg_hr"></hr>
            <h2>Vertragsunterlagen</h2>
            <p className="eg_headingText">
              Bitte speichern Sie vor dem Online-Abschluss die
              Vertragsunterlagen und Informationen als PDF.
            </p>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <StepButton
                id="herunterladen"
                className="step-button"
                type="button"
                text="Herunterladen"
                disabled={checkboxError || isFirstCheckboxClick}
                note={
                  'Laden Sie erst die Vertragsunterlagen herunter und schließen Sie dann online ab.'
                }
                step={1}
                onClick={this.handleDownloadClick}
              />
            </div>
          </div>
          <div style={{ float: 'right' }}>
            <StepButton
              className="step-button"
              id="abschlissen"
              onClick={this.handleOrderClick}
              disabled={!isDocumentDownloaded || checkboxError}
              text={'Online abschließen'}
              subText={'zahlungspflichtiger Vertrag'}
              note={
                'Bei Vertragsabschluss fallen für Sie keine weiteren Nebenkosten an'
              }
              step={orderButtonStepNumber}
              data-component-id={'next-button'}
              loading={isOrdering}
            />
          </div>
        </div>
        <Footer
          nextButtonType={NextButtonTyp.DISABLED}
          disableNextButton={true}
          handleAction={this.props.handleAction}
        />
      </>
    );
  }

  public onError = (e: Error) => {
    if (window.location.hostname === 'localhost') {
      console.log(e);
    } else {
      this.props.onError(e);
    }
  };
  private readonly handleDownloadClick = () => {
    window.open(
      `${CONFIG_BACKEND_BASE_URL}/documents/${
        this.props.storeState.businessId
      }/abschluss`,
      '_blank'
    );
    this.setState({ ...this.state, isDocumentDownloaded: true });
  };

  private readonly handleOrderClick = () => {
    if (this.isValid()) {
      this.setState({ ...this.state, isOrdering: true });
      trackElementClicked(TrackingElement.Button_OnlineAbschliessen);
      this.props.handleAction(NavigationAction.NEXT);
      this.setState({ ...this.state, isOrdering: false });
    }
  };

  private readonly isValid = () => {
    if (window.location.hostname === 'localhost') {
      return true;
    }
    return this.state.isCheckboxClicked && this.state.isDocumentDownloaded;
  };

  private readonly validateCheckBox = () => {
    this.setState({
      ...this.state,
      isFirstCheckboxClick: false,
      isCheckboxClicked: !this.state.isCheckboxClicked
    });
  };
}

export default InsuranceDocumentsPage;

import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { EditIcon } from '@eg/elements/components/Icons';
import { IsoDateString, mapToGermanDate, Person } from 'kfo-common';
import * as React from 'react';
import { HandleActionCallback } from '../../routing/StateMachine';
import { NavigationAction } from '../../routing/StateMachineTypes';
import { trackElementClicked } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface CheckoutInsuredPersonsProps {
    insuredPersons: Person[];
    handleAction: HandleActionCallback;
}

const getAge = (birthdate: IsoDateString | undefined): number => {
    const ageInMilliseconds = Date.now() - Date.parse(birthdate as string);
    const ageDate = new Date(ageInMilliseconds);
    // tslint:disable-next-line:no-magic-numbers
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const CheckoutInsuredPersons = (props: CheckoutInsuredPersonsProps) => {
    const insuredPersons = props.insuredPersons;
    if (!insuredPersons) {
        return <></>;
    }
    return (<div style={{textAlign: 'center'}}>
            <h3 style={{margin: '0 0 3em 0'}} data-component-id="checkout-insured-persons-header">Versicherte Personen</h3>

            {(insuredPersons && insuredPersons.length > 0) && <>
                {insuredPersons.map((insuredPerson, index) => {
                    return (
                        <Card padding="normal"
                              data-component-id={`checkout-insured-person-${index}`}
                              style={{textAlign: 'left'}}
                              key={`insured-person-${index}`}>
                            <div style={{fontSize: '18px', borderBottom: '1px solid #D9D9D9'}}>
                                {index + 1}. versicherte Person
                            </div>
                            <div className="esc_grid">
                                <div className="esc_grid__wrapper">
                                    <div className="esc_col esc_col-12 esc_col-m-6" style={{borderTopWidth: '10px', borderBottomWidth: '0px'}}>
                                        <h4 style={{margin: '1em 0'}}>
                                            <span data-component-id={`checkout-insured-person-${index}-name`}>
                                            {insuredPerson.vorname}{' '}
                                                {insuredPerson.nachname}
                                            </span>
                                            {' '}
                                            <span style={{color: '#737373', marginLeft: '2em', fontWeight: 'normal'}}>
                                                <small data-component-id={`checkout-insured-person-${index}-birthdate`}>
                                                    {mapToGermanDate(insuredPerson.birthdate)}
                                                </small>
                                            </span>
                                        </h4>

                                        <small>
                                            <div style={{lineHeight: '20px'}} data-component-id={`checkout-insured-person-${index}-address`}>
                                                {insuredPerson.adresse!.strasse} {insuredPerson.adresse!.hausnummer},{' '}
                                                {insuredPerson.adresse!.plz}{' '}
                                                {insuredPerson.adresse!.ort}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="esc_col esc_col-12 esc_col-m-6"
                                         style={{
                                             textAlign: 'right',
                                             borderTopWidth: '10px',
                                             borderBottomWidth: '0px',
                                             display: 'flex',
                                             flexDirection: 'column',
                                             justifyContent: 'flex-end'
                                         }}>
                                        <div data-component-id={`checkout-insured-person-${index}-age`}>
                                            <small>Alter</small>
                                            <span style={{fontSize: '1.25em', marginLeft: '.5em'}}>{getAge(insuredPerson.birthdate)} Jahre</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    );
                })}
            </>}
            <Button width="auto" variant="text-link" size="large"
                    iconLeft={EditIcon}
                    style={{fontWeight: 'bold'}}
                    onClick={() => {
                        trackElementClicked(TrackingElement.Link_VersichertePersonAendern);
                        props.handleAction(NavigationAction.DIRECT_JUMP_INSURED_PERSONS_PAGE);
                    }}
                    data-component-id="checkout-insured-persons-edit-button">
                ändern
            </Button>
        </div>
    );
};

"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Addresses;

(function (Addresses) {
  Addresses["MS"] = "MS";
  Addresses["MR"] = "MR";
  Addresses["NOT_SELECTED"] = "";
})(Addresses = exports.Addresses || (exports.Addresses = {}));
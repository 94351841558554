import * as React from 'react';
import InsuredPersonsPage, { InsuredPersonsPageData } from '../../pages/InsuredPersonsPage';
import { getOfferPageData, updateInsuredPersonsPageData } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuredPersonsPage: StateDefinition<InsuredPersonsPageData> = {
    name: StateName.INSURED_PERSONS_PAGE,
    transitions: [
        {
            test: (action, data) => action === NavigationAction.NEXT,
            newState: StateName.BANKING_DETAILS_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.POLICY_HOLDER_PAGE
        },
        // BreadCrumbs
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        // Direct jumps
        {
            test: (action, data) => action === NavigationAction.DIRECT_JUMP_REQUEST_OFFER,
            newState: StateName.REQUEST_OFFER_CHECKOUT_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuredPersons');
        const policyHolderAddress = response.policyHolder ? response.policyHolder.adresse : undefined;
        return {
            userInput: {
                policyHolderAddress,
                insuredPersons: response.insuredPersons,
                messages: response.messages
            },
            businessId: response.businessId,
            valueRanges: response.valueRanges
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        await updateInsuredPersonsPageData(inputData.businessId);
        return {};
    },
    render: (inputData, handleAction, updateApp) => {
        return <InsuredPersonsPage
            storeState={{
                businessId: inputData.businessId,
                insuredPersons: inputData.userInput.insuredPersons,
                policyHolderAddress: inputData.userInput.policyHolderAddress,
                update: (values, callback) => updateApp(values, callback)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
            showNavigateToOffer={true}
        />;
    }
};

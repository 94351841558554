
export const SESSION_STORAGE_BUSINESS_ID_KEY = 'KFOBusinessId';
export const SESSION_STORAGE_CURRENT_STATE_KEY = 'KFOCurrentState';
export const SESSION_STORAGE_OFFER_BACK_STATE = 'KFOOfferBackState';

export class Storage {
    public static writeItem = (key: string, value?: string) => {
        try {
            if (value === undefined || value === null) {
                return;
            }

            window.sessionStorage.setItem(key, value);
        } catch (e) {
            console.error('failed to write to storage', e);
        }
    };

    public static readItem = (key: string): string | undefined => {
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? item : undefined;
        } catch (e) {
            console.error('Failed to read from storage:', e);
            return undefined;
        }
    };

    public static clear = (): void => {
        try {
            window.sessionStorage.clear();
        } catch (e) {
            console.error('failed to clear storage', e);
        }
    };
}

import { ResponseCodes } from 'kfo-common';
import * as React from 'react';
import InsuranceDocumentsPage, { InsuranceDocumentsPageData } from '../../pages/InsuranceDocumentsPage';
import { getOfferPageData, makeOrder } from '../../services/api';
import { writeOfferBackJumpMarker } from '../routeHelper';
import { StateDefinition } from '../StateMachine';
import { NavigationAction, StateName } from '../StateMachineTypes';

export const insuranceDocumentsPage: StateDefinition<InsuranceDocumentsPageData> = {
    name: StateName.INSURANCE_DOCUMENTS_PAGE,
    transitions: [
        {
            test: action => action === NavigationAction.NEXT,
            newState: StateName.FEED_BACK_PAGE
        },
        {
            test: action => action === NavigationAction.BACK || action === NavigationAction.BROWSER_BACK,
            newState: StateName.INSURANCE_SUMMARY_PAGE
        },
        // BreadCrumbs
        {
            test: action => action === NavigationAction.BREADCRUMB_TARIFF_DATA,
            newState: StateName.BIRTHDATE_PAGE
        },
        {
            test: action => action === NavigationAction.BREADCRUMB_FEE,
            newState: StateName.FEE_PAGE
        },
        {
            test: (action, data) => action === NavigationAction.BREADCRUMB_PERSONAL_INFORMATION,
            newState: StateName.POLICY_HOLDER_PAGE
        }
    ],
    onEnter: async (transitionDetails, inputData) => {
        const response = await getOfferPageData(inputData.businessId, 'insuranceDocuments');

        return {
            valueRanges: response.valueRanges,
            userInput: {
                messages: response.messages
            }
        };
    },
    onExit: async (transitionDetails, inputData) => {
        writeOfferBackJumpMarker(transitionDetails);
        if (transitionDetails.action === NavigationAction.NEXT) {
            const responseStatus = await makeOrder(inputData.businessId);
            if (responseStatus !== ResponseCodes.SUCCESS) {
                throw new Error('Internal server error');
            }
        }
        return {};
    },
    render: (inputData, handleAction, updateApp, onError) => {
        return <InsuranceDocumentsPage
            storeState={{
                businessId: inputData.businessId,
                update: values => updateApp(values)
            }}
            valueRanges={inputData.valueRanges}
            handleAction={handleAction}
            onError={onError}
        />;
    }
};

import Button from '@eg/elements/Button';
import Card from '@eg/elements/Card';
import { AlertIcon, EditIcon } from '@eg/elements/components/Icons';
import { mapToGermanDate } from 'kfo-common';
import * as React from 'react';
import { mapAddressesToPrintableText } from '../../helpers/addressesHelper';
import { InsuredPersonViewModel } from '../../pages/InsuredPersonsPage';
import { trackElementClickImmediate } from '../../tracking/tracker';
import { TrackingElement } from '../../tracking/trackingConstants';

export interface TileViewProps {
    headline: string;
    insuredPerson: InsuredPersonViewModel;
    isEditable: boolean;
    editCallback: () => Promise<void>;
}

export interface TileViewState {
    editLoading: boolean;
    errorMessage: string;
}

class TileView extends React.Component<TileViewProps, TileViewState> {

    constructor(props: TileViewProps) {
        super(props);
        this.state = {
            editLoading: false,
            errorMessage: ''
        };
    }

    public render() {
        const insuredPerson = this.props.insuredPerson;
        const adresse = insuredPerson.adresse || {strasse: '', hausnummer: '', plz: '', ort: ''};
        return (
            <Card padding="normal">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-12 esc_col-m-6"
                             style={{borderTopWidth: '10px', borderBottomWidth: '0px'}}>
                            <h4 style={{margin: '.5em 0'}}>{this.props.headline}</h4>
                            <small>
                                <div data-component-id="tile-view-name" style={{lineHeight: '20px'}}>
                                    {mapAddressesToPrintableText(insuredPerson.anrede)}{' '}
                                    {insuredPerson.vorname}{' '}
                                    {insuredPerson.nachname}{' '}
                                    <span style={{color: '#737373', marginLeft: '2em'}}>
                                    {mapToGermanDate(insuredPerson.birthdate)}
                                    </span>
                                </div>
                                <div style={{lineHeight: '20px'}} data-component-id="tile-view-address">
                                    {adresse.strasse} {adresse.hausnummer}{' '}
                                    {adresse.plz}{' '}
                                    {adresse.ort}
                                </div>
                            </small>
                        </div>
                        <div className="esc_col esc_col-12 esc_col-m-6" style={{
                            borderTopWidth: '0px',
                            borderBottomWidth: '0px',
                            justifyContent: 'flex-end',
                            display: 'flex'
                        }}>

                            <Button variant="text-link"
                                    style={{fontSize: '13px', fontWeight: 'bold'}}
                                    loading={this.state.editLoading}
                                    data-component-id="tile-view-edit"
                                    onClick={async () => {
                                        trackElementClickImmediate(TrackingElement.Link_BearbeitenVP);
                                        this.setState({editLoading: true});
                                        await this.props.editCallback();
                                        this.setState({editLoading: false});
                                    }}
                                    iconLeft={EditIcon}
                                    disabled={!this.props.isEditable}>bearbeiten</Button>
                        </div>

                        {this.state.errorMessage &&
                        <div className="esc_col esc_col-12" style={{color: '#8e0038', fontSize: '13px'}}>
                            <AlertIcon
                                // tslint:disable-next-line: no-magic-numbers
                                width={16} height={16}
                            />
                            {this.state.errorMessage}
                        </div>}
                    </div>

                </div>
            </Card>
        );
    }
}

export default TileView;

import { Betreuungsweiche } from '@eg/betreuungsweiche/betreuungsweiche';
import MessageBox from '@eg/elements/MessageBox';
import * as React from 'react';
import { Headline } from '../components/Headline';
import { CONFIG_BERATUNGS_BETREUUNGS_BASE_URL } from '../config';
import { getConfigVariable } from '../helpers/configurableVariables';
import { getQueryValue } from '../helpers/QueryParameterExtractor';
import { scrollToTop } from '../helpers/scrolling';
import { Storage } from '../helpers/Storage';
import { PagePropsWithValues, StoreStateUpdater } from '../types/PageProps';

export interface FeedbackPageData extends StoreStateUpdater<FeedbackPageData> {
  anrede?: string;
  vorname: string;
  nachname: string;
  businessId: string;
  policyHolderId: string;
}

const FeedbackPage = (props: PagePropsWithValues<FeedbackPageData>) => {
  const input = props.storeState;

  React.useEffect(() => {
    scrollToTop();
    Storage.clear();
  }, []);

  return (
    <div className="feedback-page" data-component-id="feedback-page">
      <Headline>
        Herzlichen Glückwunsch! Jetzt haben Sie gut lachen, denn Sie sind
        perfekt abgesichert.
      </Headline>
      <MessageBox style={{ textAlign: 'center' }}>
        In Kürze bekommen Sie Post von ERGO.
        <br />
        Fragen? Dann rufen Sie einfach an. Oder senden Sie eine{' '}
        <a href="/de/Service/Kontakt/Allgemeine-Kontaktdaten/Kontaktformular">
          E-Mail
        </a>
        .
      </MessageBox>
      {(getQueryValue('betreuungsweiche') || getBetreuungsweicheOn()) &&
        !getQueryValue('adh_internet') && (
          <Betreuungsweiche
            data-component-id={'betreuungsweiche-feedbackPage'}
            businessId={input.businessId}
            versicherungsnehmerPdsId={input.policyHolderId}
            sparte="KRANKEN"
            tarif="ZAHN"
            url={CONFIG_BERATUNGS_BETREUUNGS_BASE_URL}
          />
        )}
      <MessageBox style={{ textAlign: 'center' }}>
        <b>Mit dem ERGO Newsletter haben Sie immer die Nase vorn.</b>
        <br />
        Sie erhalten regelmäßig wertvolle Praxistipps, Neuheiten und
        Wissenswertes rund um die Themen Versicherungen, Alltag, Sicherheit und
        Vorsorge.
        <br />
        <a href="/de/Service/Newsletter">Hier zum Newsletter anmelden</a>
      </MessageBox>
    </div>
  );
};

const getBetreuungsweicheOn = () => {
  return getConfigVariable('betreuungsweicheOn') === 'true';
};

export default FeedbackPage;
